import React, { useState } from 'react';
import { Popup, Button, NavBar } from 'antd-mobile';
import { Menu, Dropdown, Drawer, Image } from 'antd';
import { isMobile } from 'react-device-detect';
import { FaPlus } from 'react-icons/fa';

import AdButton from './ad-button';
import AdButtonSyllabus from './ad-button-syllabus';

import bgOpos from "../../assets/background-opos.jpg";
import bgAd from "../../assets/background-ad.jpg";

export const AdButtonsWrapperResponsive = (props) => {
    const [visible, setVisible] = useState(false);

    const openPopup = () => {
        setVisible(true);
    };

    const closePopup = () => {
        setVisible(false);
    };

    return (
        <div>
            <Button onClick={openPopup}>Elige el tipo de anuncio</Button>
            {isMobile ? (
                <Popup
                    visible={visible}
                    onMaskClick={closePopup}
                    bodyStyle={{ padding: '20px' }}
                >
                    <div>
                        <h3>Selecciona el tipo de anuncio que deseas publicar</h3>
                        <AdButton
                            munCode={null}
                            munName={null}
                            userId={props.userId}
                            afterFinish={() => {
                                props.afterFinish()
                            }}
                            ad={null}
                        />
                        <AdButtonSyllabus
                            munCode={null}
                            munName={null}
                            userId={props.userId}
                            afterFinish={() => {
                                props.afterFinish()
                            }}
                            ad={null} />
                    </div>
                </Popup>
            ) : (
                <Drawer
                    title="Selecciona el tipo de anuncio que deseas publicar"
                    placement="right"
                    onClose={closePopup}
                    open={visible}
                >
                    <AdButton
                        munCode={null}
                        munName={null}
                        userId={props.userId}
                        afterFinish={() => {
                            props.afterFinish()
                        }}
                        ad={null}
                    />
                    <AdButtonSyllabus
                        munCode={null}
                        munName={null}
                        userId={props.userId}
                        afterFinish={() => {
                            props.afterFinish()
                        }}
                        ad={null} />
                </Drawer>
            )}
        </div>
    );
};


export const AdButtonsDropdown = (props) => {
    const [visible, setVisible] = useState(false);

    const openPopup = () => {
        setVisible(true);
    };

    const closePopup = () => {
        setVisible(false);
    };
    const handleMenuClick = (e) => {
        /*if (e.key === 'syllabus') {
            props.setSyllabusVisible(true);
        } else if (e.key === 'home') {
            props.setSHomeVisible(true);
        }*/
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="syllabus">
                <AdButton
                    munCode={null}
                    munName={null}
                    userId={props.userId}
                    afterFinish={() => {
                        props.afterFinish()
                    }}
                    ad={null}
                    text='&nbsp;Alojamiento'
                />
            </Menu.Item>
            <Menu.Item key="home">
                <AdButtonSyllabus
                    munCode={null}
                    munName={null}
                    userId={props.userId}
                    afterFinish={() => {
                        props.afterFinish()
                    }}
                    ad={null}
                    text='&nbsp;Oposiciones'
                />
            </Menu.Item>
        </Menu>
    );

    return (
        isMobile ? (
            <>
                <Button onClick={openPopup} style={{ width: '100%' }}>
                    <FaPlus /> <small> {props.text ? props.text : ""}</small>
                </Button>
                <Popup
                    visible={visible}
                    onMaskClick={closePopup}
                    bodyStyle={{ width: "100%" }}
                    position="right"
                >
                    <NavBar
                        onBack={closePopup}
                        className="back-bar"
                        style={{ height: "64px" }}
                    >
                        ¿Qué anuncio quieres publicar?
                    </NavBar>
                    <div className='p-4 overflow'>
                        <center>
                            <Image
                                src={bgAd}
                                alt="Destino Docente anuncios"
                                style={{
                                    maxHeight: "150px",
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                                preview={false}
                            />                            <br />
                            <p>
                                Busco habitación o alquilo mi piso
                            </p>
                            <AdButton
                                munCode={null}
                                munName={null}
                                userId={props.userId}
                                afterFinish={() => { closePopup(); props.afterFinish(); }}
                                ad={null}
                                text='&nbsp;Alojamiento'
                            />
                            <br />
                            <br />
                            <Image
                                src={bgOpos}
                                alt="Destino Docente oposiciones"
                                style={{
                                    maxHeight: "150px",
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                                preview={false}
                            />
                            <br />
                            <p>
                                Vendo mi temario o soy preparador.
                            </p>
                            <AdButtonSyllabus
                                munCode={null}
                                munName={null}
                                userId={props.userId}
                                afterFinish={() => { closePopup(); props.afterFinish(); }}
                                ad={null}
                                text='&nbsp;Oposiciones'
                                wrapped={true}
                            />
                        </center>
                    </div>
                </Popup>
            </>
        ) : (
            <Dropdown overlay={menu} trigger={['click']}>
                <Button>
                    <FaPlus /> <small> {props.text ? props.text : "Publicar anuncio"}</small>
                </Button>
            </Dropdown>
        )
    );
};