import React, { useState, useEffect } from "react";
import { List, Card, Typography, Col, Row, Image } from "antd";
import {
  FaBuilding,
  FaUsers,
  FaHouseUser,
  FaBed,
  FaBath,
} from "react-icons/fa";
import axios from "axios";
import { api_key, API_ADS_URL, MEDIA_URL, HAVE_A_HOUSE } from "../../constants";
import { buildQueryString } from "../../utils/utils";
import { getAdTitle, getBaseUrlAd } from "../../utils/utils";
import { getLocalUserId } from "../../services/auth-services";
import { isAuth } from "../../services/auth-services";
import { denomAbrev } from "../../constants/data";
import MessageButton from "../messaging/message-button";
import ShareMenu from "../visual/share-menu";
import FavButton from "../visual/fav";
import { ShareFBButton, ShareWSButton, ShareXButton } from "../../utils/utils";
import AdButton from "./ad-button";

const renderItem = (item) => {
  const { Text } = Typography;

  return (
    <List.Item key={item.id} className="pt-0">
      <Card
        style={{ width: "100%" }}
        title={
          <Text
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            <h5>
              {item.ad_type === HAVE_A_HOUSE ? (
                <FaHouseUser
                  size="18"
                  style={{ marginTop: "-2px", color: "LimeGreen" }}
                />
              ) : (
                <FaUsers
                  size="16"
                  style={{ marginTop: "-2px", color: "darkred" }}
                />
              )}
              <span className="ms-2">{getAdTitle(item)}</span>
            </h5>
          </Text>
        }
        actions={
          item.creator.id !== getLocalUserId() && [
            <MessageButton
              senderId={getLocalUserId()}
              recipientId={item.creator.id}
            >
              <span className="ms-2">Contactar</span>
            </MessageButton>,
            isAuth() && <FavButton contentTypeId={44} objectId={item.id} />,
          ]
        }
        extra={
          <ShareMenu title="Comparte este anuncio" key={"share-" + item.id}>
          <ul className="share-menu">
            <li key={item.id + "-1"} className="py-2">
              <ShareWSButton
                color="rgb(0,168,132)"
                size="28"
                message={
                  "¡Míralo en en Destino Docente! " +
                  `${item.ad_title}` +
                  ` https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`
                }
              >
              </ShareWSButton>
            </li>
            <li key={item.id + "-2"} className="py-2">
              <ShareFBButton
                color="#1877f2"
                size="26"
                url={`https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`}
                message={`¡Míralo en Destino Docente! ${item.ad_title} `}
              >
              </ShareFBButton>
            </li>
            <li key={item.id + "-3"} className="py-2">
              <ShareXButton
                color="#000000"
                size="26"
                url={`https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`}
                message={`¡Míralo en Destino Docente! ${item.ad_title} `}
                hashtags="destinodocente,oposiciones"
              >
              </ShareXButton>
            </li>
          </ul>
          </ShareMenu>
        }
      >
        <Row gutter={[8, 8]}>
          <Col md={8} xs={24}>
            {item.images && item.images.length > 0 && item.images[0].image ? (
              <Image
                src={`${MEDIA_URL}${item.images[0].image}`}
                alt="Foto del anuncio"
                style={{
                  maxHeight: "125px",
                  width: "100%",
                  objectFit: "cover",
                }}
                preview={false}
              />
            ) : (
              <div
                className="py-4 text-center"
                style={{ backgroundColor: "#efefef" }}
              >
                <FaBuilding
                  size="100px"
                  style={{
                    color: "#aaaaaa",
                  }}
                />
              </div>
            )}
          </Col>
          <Col md={16} xs={24} className="px-2">
            {!!item.price && item.price > 0 && (
              <h5>{Math.round(item.price).toString()} €</h5>
            )}
            <Row gutter={[8, 8]}>
              {!!item.n_rooms && (
                <Col md={8} xs={8}>
                  <FaBed size={"20"} /> {item.n_rooms}
                </Col>
              )}
              {!!item.n_baths && (
                <Col md={6} xs={6}>
                  <FaBath size={"18"} /> {item.n_baths}
                </Col>
              )}
              {!!item.centers && item.centers.length > 0 && (
                <Col md={9} xs={24}>
                  <i className="fa fa-map-marker" aria-hidden="true" />
                  {` ${denomAbrev[item.centers[0].denom]} ${
                    item.centers[0].name
                  }`}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};

const AdsMun = (props) => {
  const [ads, setAds] = useState([]);
  const { Meta } = Card;

  const fetchDataAds = async (munCode, centerCode) => {
    const params = [
      { key: "muncode", value: munCode },
      //{ key: "centercode", value: centerCode},
      { key: "api_key", value: api_key },
    ];
    const queryString = buildQueryString(params);
    const uri = `${API_ADS_URL}/search-ads/?${queryString}`;
    try {
      const response = await axios.get(uri);
      if (response.data) {
        setAds(response.data);
      }
    } catch (error) {
      console.error("Hubo un error recuperar los datos", error);
    }
  };

  useEffect(() => {
    fetchDataAds(props.munCode);
  }, [props.munCode]);

  return (
    <>
      <h5 className="mx-3 mt-4">¿Buscas casa cerca de este centro?</h5>
      <div className="mt-3">
        <List
          dataSource={ads}
          renderItem={renderItem}
          locale={{
            emptyText: (
              <>
                <Card>
                  <Meta
                    title={
                      <div className="mt-2"> ¿No encuentras tu piso aquí?</div>
                    }
                    //description={}
                  />
                  <div className="mt-4">
                    <AdButton
                      munCode={props.munCode}
                      munName={props.munName}
                      centerCode={props.center?.code}
                      centerName={props.center?.name}
                      userId={getLocalUserId()}
                      afterFinish={() => {
                        fetchDataAds(props.munCode);
                      }}
                      title="Publica un anuncio cerca de este centro"
                      text={"Pon tu anuncio en " + props.munName}
                      ad={null}
                    />
                    <br />
                    <br />o ve al{" "}
                    <a href="/compartir-casa">
                      <span className="underlined">buscador de anuncios</span>
                    </a>
                  </div>
                </Card>
              </>
            ),
          }}
        />
      </div>
      {ads && ads.length > 0 && (
        <div className="my-2">
          <span className="me-3 blog-lead">
            ¿No encuentras tu piso aquí?
            <br />
            Publica tu anuncio y encuentra tu alojamiento
          </span>
          <AdButton
            munCode={props.munCode}
            munName={props.munName}
            centerCode={props.center?.code}
            centerName={props.center?.name}
            userId={getLocalUserId()}
            iconSize={30}
            title="Publica un anuncio cerca de este centro"
            afterFinish={() => {
              fetchDataAds(props.munCode);
            }}
          />
        </div>
      )}
    </>
  );
};

export default AdsMun;
