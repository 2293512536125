import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Layout, Row, Col, List } from "antd";
import { Form, Select, Skeleton, Input } from "antd";
import { isMobile } from "react-device-detect";
import slugify from "react-slugify";
import { Helmet } from "react-helmet-async";

import TravelButton from "../../components/travels/travel-button";
import PageHeader from "../../layout/header";
import PageFooter from "../../layout/footer";
import {
  API_CENTER_URL,
  trailing_key,
  CLIENT_SITE_URL,
  IES_DENOM,
} from "../../constants";
import {
  optionsDenom,
  optionRegions,
  denomAbrev,
  provincesByAutonomousCommunity,
} from "../../constants/data";
import { getChunkByKey } from "../../services/utils-services";
import { getValueLabel, ShareWSButton, ShareFBButton, ShareXButton } from "../../utils/utils";
import { renderAsP } from "../portal/chunk-page";
import { getLocalUserId } from "../../services/auth-services";
import { capitalize } from "../../utils/utils";

const { Content } = Layout;
const { Search } = Input;

function CentersFullList() {
  const {
    region: pRegion,
    regionName: pRegionName,
    province: pProvince,
    provinceName: pProvinceName,
    centersDenom: pCentersDenom,
  } = useParams();

  const [markers, setMarkers] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [region, setRegion] = useState(1); // And
  const [denom, setDenom] = useState(IES_DENOM); // IES
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredMarkers, setFilteredMarkers] = useState([]);

  const buildCanonicalUrl = () => {
    let url = CLIENT_SITE_URL + "/centros/lista";
    if (pRegion && pRegionName) {
      url += `/${pRegion}/${encodeURIComponent(pRegionName)}`;
    }
    if (pProvince) {
      /*url += `/provincia/${pProvince}/${slugify(
        provincesByAutonomousCommunity[pRegion]
      )}`;*/
      url += `/provincia/${pProvince}/${pProvinceName}`;
    }
    if (pCentersDenom) {
      url += `/denom/${pCentersDenom}/${slugify(
        getValueLabel(optionsDenom, pCentersDenom)
      )}`;
    }

    return url;
  };

  const getMarkers = async (region, denom, province) => {
    let uri = API_CENTER_URL + `/region/${region}/denom/${denom}`;
    if (province) uri += `/province/${province}`;
    uri += `${trailing_key}`;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(uri, { timeout: 10000 });
      //setError(response.data.length === 0);
      setMarkers(response.data);
    } catch (error) {
      setError(true);
      if (error.code === "ECONNABORTED") {
        console.error("La solicitud ha superado el tiempo de espera máximo");
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const share = (color1 = "rgb(255,255,255)", color2 = "rgb(255,255,255)") => {
    return (
      <>
        <ShareWSButton
          color={color1}
          size="24"
          message={
            "¡Míralo en Destino Docente! " +
            `Centros de (${getValueLabel(optionRegions, region)}) ` +
            `https://destinodocente.es/centros/lista/${region}/${getValueLabel(
              optionRegions,
              region
            )}`
          }
        />
        <ShareFBButton
          color={color2}
          size="24"
          url={`https://destinodocente.es/centros/lista/${region}/${getValueLabel(
            optionRegions,
            region
          )}`}
          message={
            "¡Míralo en Destino Docente! " +
            `Centros de (${getValueLabel(optionRegions, region)}) `
          }
          className={"mx-1"}
        />
        <ShareXButton
          color={color2}
          size="24"
          url={`https://destinodocente.es/centros/lista/${region}/${getValueLabel(
            optionRegions,
            region
          )}`}
          message={
            "¡Míralo en Destino Docente! " +
            `Centros de (${getValueLabel(optionRegions, region)}) `
          }
          hashtags={"destinodocente,SIPRI"}
        />
      </>
    );
  };

  const renderItem = (item) => {
    return (
      <List.Item
        key={item.id}
        actions={[
          <TravelButton
            munCodeOrig={null}
            munNameOrig={null}
            centerDest={item}
            userId={getLocalUserId()}
            iconSize={20}
          />,
        ]}
      >
        <div className="py-2">
          <a href={`/centros${item.absolute_url}`}>
            <b>
              {denomAbrev[item.denom]} "{item.name}"
            </b>
          </a>{" "}
          de {item.city},{"  "}
          {item.province}
        </div>
      </List.Item>
    );
  };

  useEffect(() => {
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    if (pCentersDenom) setDenom(pCentersDenom);
    if (pRegion) {
      setRegion(pRegion);
      setProvinces(provincesByAutonomousCommunity[pRegion]);
      if (pProvince) {
        setSelectedProvince(pProvince);
        getMarkers(pRegion, denom, selectedProvince);
      } else {
        getMarkers(pRegion, denom)
      }
    } else {
      setRegion(1); // Andalucia
      setProvinces(provincesByAutonomousCommunity[1]);
      getMarkers(region, denom);
    }
    /*
    document.title =
      "Destino Docente. Centros de secundaria y primaria de " +
      capitalize(pProvinceName) + " " +
      getValueLabel(optionRegions, region) +
      ", España.";
    */
    fetchDataChunk("list");
  }, []);

  useEffect(() => {
    const filteredData = markers.filter(
      (item) =>
        item.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.city?.toLowerCase().includes(searchText.toLowerCase())
    );
    if (!filteredData || filteredData?.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
    setFilteredMarkers(filteredData);
  }, [searchText]);

  const onChangeDenom = (value) => {
    setDenom(value);
    setFilteredMarkers(null);
    setSearchText("");
    getMarkers(region, value, selectedProvince);
  };

  const onChangeRegion = (value) => {
    const provinces = provincesByAutonomousCommunity[value] || [];
    setRegion(value);
    setSelectedProvince(null);
    setProvinces(provinces);
    setMarkers([]);
    setFilteredMarkers(null);
    setSearchText("");
    getMarkers(value, denom, null);
  };

  const onChangeProvince = (value) => {
    setSelectedProvince(value);
    setMarkers([]);
    setFilteredMarkers(null);
    setSearchText("");
    getMarkers(region, denom, value);
  };

  return (
    <Layout className="full-height-percent">
      <Helmet>
        <link rel="canonical" href={buildCanonicalUrl()} />
        <meta
          name="description"
          content="Lista de centros de secundaria y primaria de 
                Andalucía, Aragón, Castilla y León, Castilla-La Mancha, Galicia, Madrid, Murcia y Comunidad Valenciana."
        />
        <title>
          {"Destino Docente. Centros de secundaria y primaria de " +
            capitalize(pProvinceName) + " " +
            getValueLabel(optionRegions, region) +
            ", España."}
        </title>
      </Helmet>
      <PageHeader />
      <Content className="content">
        <Form
          name="search-form"
          className="p-3"
          initialValues={{
            ["region"]: pRegionName ? pRegionName : "Andalucía",
            ["denom"]: pCentersDenom
              ? getValueLabel(optionsDenom, pCentersDenom)
              : "Instituto de Educación Secundaria",
            ["province"]:
              pProvince && pRegion
                ? getValueLabel(
                  provincesByAutonomousCommunity[pRegion],
                  pProvince
                )
                : "",
          }}
        >
          <Row gutter={[12, 8]}>
            <Col md={5} xs={12}>
              <Form.Item name="region" className="mb-0">
                <Select onChange={onChangeRegion} options={optionRegions} />
              </Form.Item>
            </Col>
            <Col md={5} xs={12}>
              <Form.Item name="province" className="mb-0">
                <Select
                  onChange={onChangeProvince}
                  options={provinces}
                  placeholder="Selecciona provincia"
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col md={4} xs={12}>
              <Form.Item name="denom" className="mb-0">
                <Select onChange={onChangeDenom} options={optionsDenom} />
              </Form.Item>
            </Col>

            {!isMobile && (
              <>
                <Col md={6} xs={16} className="pt-1 mb-0">
                  {markers?.length > 0 && (
                    <h1 style={{ fontSize: "1.33rem" }}>
                      {markers.length} centros de{" "}
                      {getValueLabel(optionRegions, region)}
                    </h1>
                  )}
                </Col>
                <Col md={3} xs={6} className="mb-0 text-center">
                  {markers?.length > 0 && share()}
                </Col>
              </>
            )}
          </Row>
        </Form>

        <div
          className="map-container-map-container-full"
          style={{ position: "relative" }}
        >
          <div className="list-container-list-container-full col-md-8 col-xs-12 offset-md-2 pb-2">
            {renderAsP(text)}

            {!!loading ? (
              <div className="text-center">
                <Skeleton className="p-3 mt-3" active />
              </div>
            ) : (
              <>
                {!!markers?.length > 0 && (
                  <div className="col-xs-12 col-md-4 ps-2 my-3">
                    <Search
                      placeholder="Buscar"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                )}
                <List
                  dataSource={
                    filteredMarkers?.length > 0 ? filteredMarkers : markers
                  }
                  renderItem={renderItem}
                  size="small"
                  pagination={{
                    pageSize: 20,
                  }}
                  locale={{ emptyText: "¡Vaya! no hay centros" }}
                  className="mb-4"
                  loading={loading}
                />
              </>
            )}
          </div>
        </div>
      </Content>
      <PageFooter />
    </Layout>
  );
}

export default CentersFullList;
