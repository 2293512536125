import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Layout, Row, Col, Form, Select, Spin } from "antd";
import { isMobile } from "react-device-detect";
import slugify from "react-slugify";
import { Helmet } from "react-helmet-async";

import Map from "../../components/centers-map";
import MunicipalityAutocomplete from "../../components/municipality-autocomplete";
import PageHeader from "../../layout/header";
import { ShareWSButton, ShareFBButton, ShareXButton } from "../../utils/utils";
import {
  API_CENTER_URL,
  IES_DENOM,
  trailing_key,
  CLIENT_SITE_URL,
} from "../../constants";
import { optionsDenom, optionRegionsAll } from "../../constants/data";
import { renderAsP } from "../portal/chunk-page";
import { getChunkByKey } from "../../services/utils-services";
import { getValueLabel } from "../../utils/utils";
import { antIcon } from "../../components/visual/load-icon";

const { Content } = Layout;

function CentersFullMap() {
  let navigate = useNavigate();

  const [markers, setMarkers] = useState([]);
  const [region, setRegion] = useState(null);
  const [denom, setDenom] = useState(IES_DENOM); // IES
  const [defaultMapCenterCoords] = useState([40.189214, -3.667379]); //Centro ES
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    region: pRegion,
    regionName: pRegionName,
    positionCode: pPositionCode,
    positionName: pPositionName,
  } = useParams();

  const buildCanonicalUrl = () => {
    let url = CLIENT_SITE_URL + "/mapas";
    if (pRegion && pRegionName) {
      url += `/${pRegion}/${encodeURIComponent(pRegionName)}`;
    }
    if (pPositionCode && pPositionName) {
      url += `/puesto/${pPositionCode}/${slugify(pPositionName)}`;
    }
    return url;
  };

  const getMarkers = (region, denom) => {
    setLoading(true);
    let uri = API_CENTER_URL;
    if (region) {
      uri += `/region/${region}`;
    }
    if (denom) {
      uri += `/denom/${denom}`;
    }
    if (pPositionCode) {
      uri += `/position/${pPositionCode}`;
    }
    uri += trailing_key;
    if (uri) {
      setMarkers([]);
      axios
        .get(uri)
        .then((response) => {
          setMarkers(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setMarkers([]);
          setLoading(false);
        });
    } else {
      setMarkers([]);
      setLoading(false);
    }
  };

  const share = (color1 = "rgb(255,255,255)", color2 = "rgb(255,255,255)", color3 = "rgb(0,0,0)") => {
    return (
      <>
        <ShareWSButton
          color={color1}
          size="24"
          message={
            "¡Míralo en Destino Docente!  " +
            `Centros de (${getValueLabel(optionRegionsAll, region)}) ` +
            `https://destinodocente.es/mapas/${region}/${slugify(
              getValueLabel(optionRegionsAll, region)
            )}`
          }
        />

        <ShareFBButton
          color={color2}
          size="24"
          url={`https://destinodocente.es/mapas/${region}/${slugify(
            getValueLabel(optionRegionsAll, region)
          )}`}
          message={
            "¡Míralo en Destino Docente!  " +
            `Centros de (${getValueLabel(optionRegionsAll, region)}) `
          }
          className="mx-1"
        />

        <ShareXButton
          color={color3}
          size="24"
          url={`https://destinodocente.es/mapas/${region}/${slugify(
            getValueLabel(optionRegionsAll, region)
          )}`}
          message={
            "¡Míralo en Destino Docente!  " +
            `Centros de (${getValueLabel(optionRegionsAll, region)}) `
          } 
          hashtags={"destinodocente,SIPRI,Andalucia"}
        />

      </>
    );
  };

  useEffect(() => {
    if (pRegion) {
      setRegion(pRegion);
    }
  }, []);

  useEffect(() => {
    getMarkers(pRegion ? pRegion : region, denom); // lo hago asi por que no coge el state bien
  }, [region]);

  useEffect(() => {
    document.title =
      "Destino Docente. Mapa de centros de secundaria y primaria " +
      getValueLabel(optionRegionsAll, region) + " " + pPositionName;

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("maps");
  }, []);

  const onChangeDenom = (value) => {
    setDenom(value);
    getMarkers(region, value);
  };

  const onChangeRegion = (value) => {
    setRegion(value);
    getMarkers(value, denom);
    document.title =
      "Destino Docente. Mapa de centros de secundaria y primaria " +
      getValueLabel(optionRegionsAll, region) + " " + pPositionName;
  };

  const onSelectMunicipalities = (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const coordinates = children[1].props.children;
      const munCode = children[2].props.children;
      navigate(
        "/centros/" +
        coordinates[1] +
        "/" +
        coordinates[0] +
        "/" +
        encodeURI(value) +
        "/" +
        munCode +
        (pPositionCode
          ? "/puesto/" + pPositionCode + "/" + pPositionName
          : "")
      );
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={buildCanonicalUrl()} />
        <meta
          name="description"
          content="Mapa de centros de secundaria y primaria de Andalucía, Aragón, Castilla y León, Castilla-La Mancha, Galicia, Madrid, Murcia y Comunidad Valenciana."
        />
      </Helmet>
      <Layout className="full-height">
        <PageHeader />
        <Content className="content">
          <Form name="search-form" className="p-3">
            <Row gutter={[12, 8]}>
              <Col md={5} xs={12}>
                <Form.Item name="region" className="mb-0">
                  <Select
                    onChange={onChangeRegion}
                    defaultValue={pRegionName ? pRegionName : "Todos"}
                    options={optionRegionsAll}
                  />
                </Form.Item>
              </Col>
              <Col md={5} xs={12}>
                <Form.Item name="denominacion" className="mb-0">
                  <Select
                    onChange={onChangeDenom}
                    defaultValue={"Instituto de Educación Secundaria"}
                    options={optionsDenom}
                  />
                </Form.Item>
              </Col>
              {!isMobile && (
                <>
                  <Col md={0} xs={0}></Col>
                  <Col md={10} xs={16} className="pt-1 mb-0">
                    {markers && markers?.length > 0 ? (
                      <h1 style={{ fontSize: "1.1rem" }}>
                        {markers.length} centros{" "}
                        {getValueLabel(optionRegionsAll, region)}
                        {pPositionName}
                      </h1>
                    ) : (
                      <h5>
                        No hay centros {getValueLabel(optionRegionsAll, region)}
                      </h5>
                    )}
                  </Col>
                  <Col md={3} xs={0} className="text-center">
                    {!isMobile && share()}
                  </Col>
                </>
              )}
            </Row>
          </Form>

          <div
            className="map-container map-container-full"
            style={{ position: "relative" }}
          >
            {!!loading && (
              <div className="mask text-center">
                <Spin
                  indicator={antIcon}
                  style={{ marginTop: "45vh", color: "#fff" }}
                />
              </div>
            )}
            <Map
              centerCoords={defaultMapCenterCoords} // centro de España
              markers={markers}
              radius={null}
              coords={defaultMapCenterCoords}
              clustered={true}
            />
            <div
              className="mapa-leyenda p-3 rounded"
              style={{
                maxWidth: isMobile ? "95vw" : "30vw",
                maxHeight: "33vh",
              }}
            >
              {renderAsP(text)}
              <div className="mb-0">
                <Form name="search-form-main">
                  <Form.Item name="origin">
                    <MunicipalityAutocomplete
                      autoFocus={false}
                      onSelectMunicipalities={onSelectMunicipalities}
                      placeHolder="Escribe el municipio donde vives"
                      className="search-form-main-input"
                      fullOnclick={true}
                    />
                  </Form.Item>
                </Form>
              </div>
              {isMobile && markers && (
                <div className="pull-right">
                  {share("rgb(0,168,132)", "#1877f2")}
                </div>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default CentersFullMap;
