import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Layout, Row, Col, Drawer, Spin, Tour, Card, Typography } from "antd";
import { Form, Select, Slider, Button, Tooltip, notification } from "antd";
import { Slider as SliderMobile, Tabs, Popup, NavBar } from "antd-mobile";
import { FaAt, FaPhone, FaDirections, FaArrowRight } from "react-icons/fa";
import { ControlOutlined } from "@ant-design/icons";
import slugify from "react-slugify";
import { Helmet } from "react-helmet-async";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import { createCenterDistance } from "../dao/center-dao";
import {
  API_CENTER_URL,
  trailing_key,
  CLIENT_SITE_URL,
} from "../constants/index";
import { optionsDenom, distanceMarks, denomAbrev } from "../constants/data";
import { handleStyle, trackStyle, railStyle } from "../constants/display";
import { getLatestPlazas } from "../services/center-services";
import {
  pluralize,
  getValueLabel,
  getAproxCoordsRound,
  ShareFBButton,
  ShareWSButton,
  ShareXButton,
} from "../utils/utils";
import { getMunByLatLon, getMunByCode } from "../services/utils-services";
import { antIcon } from "../components/visual/load-icon";
import { IES_DENOM } from "../constants/index";
import Map from "../components/centers-map.js";
import CenterMap from "../components/center-map";
import MunicipalityAutocomplete from "../components/municipality-autocomplete";
import CenterDistancesList from "../components/center-distance-list";
//import CenterInitialPositions from "../components/center-positions-initial";
//import CenterTrasladosPositions from "../components/center-positions-tralados";
import CenterDistance from "../components/center-distance-local";
import CenterTempPositions from "../components/center-positions-temporary";
import LoginButton from "../components/auth/login-button.js";
import PageHeader from "../layout/header.js";
import TravelsMunCenter from "../components/travels/travels-mun-center.js";
import AdsMun from "../components/ads/ads-mun.js";

const { Content, Footer } = Layout;
//const { Meta } = Card;
const { Text } = Typography;

const urlRadius = API_CENTER_URL + "/radius";

function CentersPage(props) {
  const [markers, setMarkers] = useState([]);
  const [radius, setRadius] = useState(10); // 10 km
  const [denom, setDenom] = useState(IES_DENOM); // IES
  const [munCode, setMunCode] = useState(null);
  const [munName, setMunName] = useState(null);
  const [coords, setCoords] = useState(undefined);
  const [coordsOrigin, setCoordsOrigin] = useState(null);
  const [aproxCoords, setAproxCoords] = useState(null);
  const [defaultMapCenterCoords] = useState([40.189214, -3.667379]); //Centro
  const [disabled, setDisabled] = useState(true);
  const [showExtraFields, setShowExtraFields] = useState(true);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [showMapCenter, setShowMapCenter] = useState(false);
  const [loading, setLoading] = useState(!!props.accEnabled);
  const [positionCode, setPositionCode] = useState(null);
  const [isTourOpened, setIsTourOpened] = useState(false);
  const [isTourPosOpened, setIsTourPosOpened] = useState(false);

  const location = useLocation();
  //const [form] = Form.useForm();

  // parámetros en url
  const {
    latitude: pLatitude,
    longitude: pLongitude,
    municipalityName: pMunicipalityName,
    municipalityCode: pMunicipalityCode,
    centersDenom: pCentersDenom,
    fromRadius: pFromRadius,
    positionCode: pPositionCode,
    positionName: pPositionName,
  } = useParams();

  // query string
  const [searchParams, setSearchParams] = useSearchParams();

  // Tour
  const refMun = useRef(null);
  const refDenom = useRef(null);
  const refRadius = useRef(null);

  // tour puesto
  const tourStepsPosition = [
    {
      title: "Escribe el municipio",
      description: (
        <>
          Así podrás encontrar centros con el puesto "
          {pPositionName ? pPositionName : ""}"
        </>
      ),
      placement: "bottom",
      target: () => refMun.current,
    },
  ];

  // tour general
  const tourSteps = [
    {
      title: "Municipio",
      description: "Escribe el nombre de municipio",
      placement: "bottom",
      target: () => refMun.current,
    },
    {
      title: "Denominación",
      description: "Selecciona la denominación",
      target: () => refDenom.current,
    },
    {
      title: "Radio",
      description: "Selecciona el radio de búsqueda",
      target: () => refRadius.current,
    },
  ];

  // si recibo por state
  /*
  useEffect(() => {
    if (location.state) {
    }
  }, [location.state]);
  */

  // localización de dispositivo
  useEffect(() => {
    if (!!props.accEnabled || searchParams.get("acc_enabled")) {
      if (navigator.geolocation) {
        /*
        notification.open({
          message: "Obteniendo tu ubicación",
          description: "Tomará un momento",
        });
        */
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            setCoordsOrigin([pos.coords.latitude, pos.coords.longitude]);
            setCoords([pos.coords.latitude, pos.coords.longitude]); // pone el centro del mapa
            setAproxCoords(
              getAproxCoordsRound(pos.coords.latitude, pos.coords.longitude)
            );
            setShowMapCenter(true);

            getMunByLatLon(pos.coords.latitude, pos.coords.longitude)
              .then((data) => {
                if (data) {
                  if (data) {
                    setMunCode(data.codigo_ine);
                    setMunName(data.municipio);
                  } else {
                    setMunCode(null);
                    setMunName(null);
                    setLoading(false); // para quitar el mask loader
                  }
                }
              })
              .catch((error) => {
                setMunCode(null);
                setMunName(null);
                setLoading(false); // para quitar el mask loader
                notification.warning({
                  message: "No hemos podido obtener centros cercanos",
                  description: "Inténtalo con el buscador de municipios",
                });
                console.error(error);
              });

            if (munCode) {
              // puede ser redundante, por el otro hook
              getMarkers(pos.coords, radius, denom, munCode, positionCode);
            }
          },
          (error) => {
            var msg = "";
            switch (error.code) {
              case error.PERMISSION_DENIED:
                msg =
                  "Se ha denegado el acceso. Comprueba por favor si tienes el GPS activado y los permisos adecuados";
                break;
              case error.POSITION_UNAVAILABLE:
                msg = "Información de localización no disponible";
                break;
              case error.TIMEOUT:
                msg = "Ha transcurrido el tiempo de espera";
                break;
              case error.UNKNOWN_ERROR:
                msg = "Error desconocido en localización";
                break;
              default:
                msg = "Error";
            }
            setLoading(false); // para quitar el mask loader
            notification.warning({
              message: "Error en geolocalización",
              description: msg,
            });
          }
        );
      } else {
        notification.error({
          message: "Localización no disponible",
        });
      }
    }
  }, [props.accEnabled]);

  // obtengo marcadores
  useEffect(() => {
    if (coords && radius && denom && munCode)
      getMarkers(coords, radius, denom, munCode, positionCode);
  }, [coords, radius, denom, munCode, positionCode]);

  // compruebo cache local de distancias
  useEffect(() => {
    if (denom && munCode && markers)
      distanceMatrixToLocal(denom, munCode, markers);
  }, [denom, munCode, markers]);

  useEffect(() => {
    if (coordsOrigin && munName)
      notification.success({
        message: "Ubicación obtenida de tu dispositivo",
        description: munName ? "Buscando en la zona de " + munName : "",
        // getAproxCoordsRound(coordsOrigin[0], coordsOrigin[1]),
      });
  }, [coordsOrigin, munName]);

  // localización precisa de centros con acc_enabled
  useEffect(() => {
    if (coordsOrigin && markers) {
      distanceMatrixToLocalFromOrigin(
        coordsOrigin[0],
        coordsOrigin[1],
        markers
      );
    }
  }, [coordsOrigin, markers]);

  // efecto sin condiciones
  useEffect(() => {
    document.title =
      "Destino Docente. Centros de secundaria y primaria" +
      (!!munName ? " cerca de " + munName : "") +
      (!!radius ? " " + radius + "km" : "");

    // si hay parámetros GET
    if (
      !!pLatitude &&
      !!pLongitude &&
      !!pMunicipalityName &&
      !!pMunicipalityCode
    ) {
      setCoords([pLatitude, pLongitude]);
      setMunCode(pMunicipalityCode);
      //setMunName(pMunicipalityName);
    }
    if (!!pCentersDenom) {
      setDenom(pCentersDenom);
    }
    if (!!pFromRadius) {
      setRadius(pFromRadius);
    }

    if (!!pPositionCode) {
      setPositionCode(pPositionCode);
      // tour visual
      if (!props.accEnabled && !pMunicipalityCode) setIsTourPosOpened(true);
    }

    getLatestPlazas(); //cargo las plazas sipri al principio
  }, []);

  // apaño para traducir texto de botones del componente Tour TODO: sacar a custom Tour
  useEffect(() => {
    let attempt = 0; // Contador de intentos
    const maxAttempts = 10; // Número máximo de intentos

    const updateButtonLabels = () => {
      // Seleccionar los span dentro de los botones que tienen una clase específica
      const buttons = document.querySelectorAll(
        ".ant-tour-buttons button span"
      );
      if (buttons && buttons.length) {
        buttons.forEach((span) => {
          switch (
          span.textContent // Verifica el texto actual dentro del span
          ) {
            case "Next":
              span.textContent = "Siguiente";
              break;
            case "Previous":
              span.textContent = "Anterior";
              break;
            case "Finish":
              span.textContent = "Cerrar";
              break;
            default:
              break;
          }
        });
        clearInterval(intervalId); // Detener el intervalo si los botones son encontrados y actualizados
      } else {
        attempt++;
        if (attempt >= maxAttempts) {
          clearInterval(intervalId); // Detener el intervalo después de un número máximo de intentos
        }
      }
    };

    // Establecer un intervalo para asegurar que los cambios se realicen
    const intervalId = setInterval(updateButtonLabels, 100);

    return () => clearInterval(intervalId); // Limpieza al desmontar
  }, []);

  useEffect(() => {
    const fetchMunName = async (code) => {
      try {
        try {
          const data = await getMunByCode(code);
          if (data) {
            setMunName(data.name);
          }
        } catch (error) {
          console.error("Error:", error.message);
        }
      } catch (error) {
        console.log("Error fetching data");
      }
    };

    document.title =
      "Destino Docente. Centros de secundaria y primaria" +
      (!!munName ? " cerca de " + munName : "");

    if (!!pMunicipalityCode) {
      fetchMunName(pMunicipalityCode);
    }
  }, [pMunicipalityCode]);

  /**
   *
   * @param {*} denom
   * @param {*} munCode
   * @param {*} markers
   */
  function setMarkersToLocal(denom, munCode, markers) {
    if (munCode && denom && markers) {
      const markerDataArray = markers.map((marker) => {
        const markerData = {
          name: marker.name,
          denom: marker.denom__name,
          address: marker.address,
          zip_code: marker.zip_code,
          city: marker.city,
          province: marker.province,
          phone: marker.phone,
          latitude: marker.latitude,
          longitude: marker.longitude,
          id: marker.id,
        };
        // guardo centro a centro
        // const keycenter = `centers-${marker.id}`;
        // localStorage.setItem(keycenter, JSON.stringify(markerData));
        return markerData;
      });

      const key = `centersFrom-${munCode}-${denom}`;
      localStorage.setItem(key, JSON.stringify(markerDataArray));
    }
  }

  /**
   *
   * @param {*} munCode
   */
  function distanceMatrixToLocal(denom, munCode, markers) {
    const markersKey = `centersFrom-${munCode}-${denom}`;
    const markersJSON = localStorage.getItem(markersKey);
    const markerDataArray = JSON.parse(markersJSON);
    let destinations = [];
    let hasStoredMarkers = false;
    // intento recuperar de localstorage si tengo duraciones, pero parece que no está funcionando bien
    // si no tengo la lista de markers en localstorage
    if (!markerDataArray || markerDataArray?.length === 0) {
      destinations = markers
        .filter(
          (marker) =>
            marker.latitude &&
            marker.longitude &&
            !localStorage.getItem(`${munCode}-${marker.id}`)
        )
        .map((marker) => [marker.latitude, marker.longitude, marker.id]);
    } else {
      hasStoredMarkers = true;
      destinations = markerDataArray
        .filter(
          (marker) =>
            marker.latitude &&
            marker.longitude &&
            !localStorage.getItem(`${munCode}-${marker.id}`)
        )
        .map((marker) => [marker.latitude, marker.longitude, marker.id]);
    }
    // Coordenadas de destino, utilizando la latitud y longitud de cada objeto markerData
    if (destinations && destinations?.length > 0) {
      const dest = encodeURI(destinations);
      //const dest = encodeURI(destinations.slice(0, 100));  // limito a 100 centros el cálculo de distancia
      const uri =
        API_CENTER_URL +
        `/distance-matrix/${munCode}/distance-to/${dest}` +
        trailing_key;
      axios
        .post(uri)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const resultado = response.data[i];
            const idCenter = resultado.id;
            const distancia = resultado.distancia;
            const duracion = resultado.duracion;
            const key = `${munCode}-${idCenter}`;

            const data = createCenterDistance(
              munCode,
              idCenter,
              null,
              null,
              null,
              Math.round(distancia * 10) / 10 + " km",
              duracion + " min",
              "ORS"
            );
            localStorage.setItem(key, JSON.stringify(data));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  /**
   * Prueba para obtener distancias desde unas coordenadas precisas
   * @param {*} denom
   * @param {*} latitude
   * @param {*} longitude
   * @param {*} markers
   */
  function distanceMatrixToLocalFromOrigin(latitude, longitude, markers) {
    const destinations = markers.map((marker) => [
      marker.latitude,
      marker.longitude,
      marker.id,
    ]);

    if (destinations && destinations?.length > 0) {
      const dest = encodeURI(destinations);

      const uri =
        API_CENTER_URL +
        `/distance-matrix-from/${aproxCoords[0]},${aproxCoords[1]}/to/${dest}` +
        trailing_key;
      axios
        .post(uri)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const resultado = response.data[i];

            const idCenter = resultado.id;
            const distancia = resultado.distancia;
            const duracion = resultado.duracion;
            // aproximo con un factor 10
            const key = `origin-${aproxCoords[0]},${aproxCoords[1]}-${idCenter}`;
            const data = createCenterDistance(
              null,
              idCenter,
              [latitude, longitude],
              null,
              null,
              Math.round(distancia * 10) / 10 + " km",
              duracion + " min",
              "ORS"
            );
            localStorage.setItem(key, JSON.stringify(data));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  /**
   * Devuelve puestos disponibles para los marcadores
   * @param {*} markers
   *
  function getCentersPositions(markers) {
    const markersKey = `centersFrom-${munCode}-${denom}`;
    const markersJSON = localStorage.getItem(markersKey);
    const markerDataArray = JSON.parse(markersJSON);
    let centers = null;
    let hasStoredMarkers = false;
    // saco una lista de ids de centros
    if (!markerDataArray || markerDataArray?.length === 0) {
      centers = markers.map((marker) => [marker.id]);
    } else {
      hasStoredMarkers = true;
      centers = markerDataArray.map((marker) => [marker.id]);
    }

    if (centers && centers?.length > 0) {
      const dest = encodeURI(centers);
      // TODO: filtrar por centros. Ahora carga al principio todos
      getLatestPlazas();
    }
  }
  */

  /**
   *  Función principal de carga de datos
   * @param {*} coord
   * @param {*} radius
   * @param {*} denom
   * @param {*} positionCode
   *
   */
  const getMarkers = (coord, radius, denom, munCode, positionCode) => {
    if (coord && radius && denom && munCode) {
      let uri = urlRadius + `/${radius}/${coord[0]}/${coord[1]}/denom/${denom}`;
      if (positionCode) uri += `/position/${positionCode}`;
      uri += trailing_key;
      setLoading(true);
      setDisabled(true);
      setMarkers([]);
      axios
        .get(uri)
        .then((response) => {
          setMarkers(response.data);
          setMarkersToLocal(denom, munCode, response.data);
          setDisabled(false);
          setLoading(false);
        })
        .catch((error) => {
          setDisabled(false);
          setLoading(false);
        });
    } else {
      setDisabled(false);
      setMarkers([]);
      setLoading(false);
    }
  };

  // llamada en lista para PullList
  const updateMarkers = () => {
    return getMarkers(coords, radius, denom, munCode, positionCode);
  };

  const buildCanonicalUrl = () => {
    let url = CLIENT_SITE_URL + "/centros";
    if (!!props.accEnabled || searchParams.get("acc_enabled"))
      url += "/cercanos";
    if (pLatitude && pLongitude && pMunicipalityName && pMunicipalityCode) {
      url += `/${pLatitude}/${pLongitude}/${slugify(
        pMunicipalityName
      )}/${pMunicipalityCode}`;

      if (pCentersDenom && pFromRadius) {
        url += `/${pCentersDenom}/${slugify(
          getValueLabel(optionsDenom, pCentersDenom)
        )}/radio/${pFromRadius}`;
      } else if (pPositionCode && pPositionName) {
        url += `/puesto/${pPositionCode}/${slugify(pPositionName)}`;
      }
    } else if (pPositionCode && pPositionName) {
      url += `/puesto/${pPositionCode}/${slugify(pPositionName)}`;
    }
    return url;
  };

  // eventos de componentes
  const onChangeDenom = (value) => {
    setDenom(value);
    getMarkers(coords, radius, denom, munCode);
  };

  const onChangeSlider = (value) => {
    setRadius(value);
    getMarkers(coords, radius, denom, munCode, positionCode);
  };

  const onSelectMunicipalities = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      let coordinates = children[1].props.children;
      const code = children[2].props.children;
      setAproxCoords(null); // sirve como flag
      setCoordsOrigin(null);
      try {
        const data = await getMunByCode(code);
        if (data) {
          coordinates = [data.longitude, data.latitude];
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
      setCoords([coordinates[1], coordinates[0]]);
      setMunCode(code); // munCode
      setMunName(value); // name
      getMarkers(coordinates, radius, denom, code, positionCode);
      setShowMapCenter(false);
    }
  };

  const onClearMunicipalities = () => {
    setCoords(undefined);
    setMunCode(null);
    setMunName(null);
    setRadius(10);
    getMarkers(undefined, radius, denom, null);
  };

  // boton filtros en mobile
  const onClicShowExtraFields = () => {
    setShowExtraFields(!showExtraFields);
  };

  // modal de centros
  const onCenterClick = (item) => {
    if (item !== undefined && item !== null) {
      setSelectedCenter(item);
      setIsModalOpened(true);
    }
  };

  return (
    <>
      <Tour
        open={isTourPosOpened}
        onClose={() => setIsTourPosOpened(false)}
        steps={tourStepsPosition}
      />
      {/* TODO: tour generico para primera vez controlado por cookie */}
      <Tour
        open={isTourOpened}
        onClose={() => setIsTourOpened(false)}
        steps={tourSteps}
      />

      <Helmet>
        <link rel="canonical" href={buildCanonicalUrl()} />
        <meta
          name="description"
          content={
            "Ahorra tiempo encontrando centros para tu solicitud de destino de secundaria y primaria " +
            (!!munName ? " cerca de " + munName : "") +
            (!!radius ? " " + radius + "km" : "")
          }
        />
        <meta
          property="og:title"
          content={
            "Destino Docente. Buscador de centros para tu próximo destino"
          }
        />
        <meta
          property="og:description"
          content="Ahorra tiempo encontrando centros para tu solicitud de destino"
        />
        <meta
          property="og:image"
          content="https://destinodocente.es/logo_comp.jpg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://destinodocente.es/centros`} />
      </Helmet>
      {/*<ClearLocalStorageInterval />*/}
      <Layout className="full-height">
        {!props.isApp && <PageHeader />}
        <Content className="content">
          <BrowserView>
            <Form
              name="search-form"
              className="p-3"
            //form={form}
            /*
            initialValues={[
              {
                name: ["origin"],
                value: munName,
              },
            ]}
            /*
            fields={[
              {
                name: ["origin"],
                value: munName,
              },
            ]}*/
            >
              <Row gutter={[24, 8]}>
                <Col md={5}>
                  <Form.Item>
                    <div ref={refMun}>
                      <MunicipalityAutocomplete
                        onSelectMunicipalities={onSelectMunicipalities}
                        placeHolder={"Escribe el municipio donde vives"}
                        defaultValue={munName}
                        onClearMunicipalities={onClearMunicipalities}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col md={5}>
                  <Form.Item>
                    <div ref={refDenom}>
                      <Select
                        onChange={onChangeDenom}
                        defaultValue={
                          pCentersDenom
                            ? getValueLabel(optionsDenom, pCentersDenom)
                            : "Instituto de Educación Secundaria"
                        }
                        options={optionsDenom}
                        disabled={disabled}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item>
                    <div ref={refRadius}>
                      <Slider
                        min={10}
                        step={null}
                        marks={distanceMarks}
                        defaultValue={pFromRadius ? pFromRadius : radius}
                        onChange={onChangeSlider}
                        disabled={disabled}
                        trackStyle={trackStyle}
                        railStyle={railStyle}
                        handleStyle={handleStyle}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col md={6} className="pt-1 text-center">
                  {markers?.length > 0 && (
                    <h1 style={{ fontSize: "1.1rem" }}>
                      {markers.length} centros cerca de {munName}
                    </h1>
                  )}
                </Col>
                <Col md={2} className="float-right">
                  {markers?.length > 0 && (
                    <>
                      <ShareWSButton
                        color="rgb(255,255,255)"
                        size="22"
                        message={
                          "¡Míralo en Destino Docente! " +
                          pluralize(Object.keys(markers).length, "centro") +
                          ` (${getValueLabel(
                            optionsDenom,
                            denom
                          )}) a ${radius}km de ${munName}. ` +
                          `${CLIENT_SITE_URL}/centros/${coords[0]}/${coords[1]
                          }/${munName}/${munCode}/${denom}/${slugify(
                            getValueLabel(optionsDenom, denom)
                          )}/radio/${radius}`
                        }
                      />
                      <ShareFBButton
                        color="rgb(255,255,255)"
                        size="22"
                        className={"px-1"}
                        url={`${CLIENT_SITE_URL}/centros/${coords[0]}/${coords[1]
                          }/${munName}/${munCode}/${denom}/${slugify(
                            getValueLabel(optionsDenom, denom)
                          )}/radio/${radius}`}
                        message={
                          "¡Míralo en Destino Docente! " +
                          pluralize(Object.keys(markers).length, "centro") +
                          ` ${denomAbrev[getValueLabel(
                            optionsDenom,
                            denom
                          )]} a ${radius}km de ${munName}.`
                        }
                      />
                      <ShareXButton
                        color="rgb(255,255,255)"
                        size="20"
                        url={`${CLIENT_SITE_URL}/centros/${coords[0]}/${coords[1]
                          }/${munName}/${munCode}/${denom}/${slugify(
                            getValueLabel(optionsDenom, denom)
                          )}/radio/${radius}`}
                        message={
                          "¡Míralo en Destino Docente! " +
                          pluralize(Object.keys(markers).length, "centro") +
                          ` (${getValueLabel(
                            optionsDenom,
                            denom
                          )}) a ${radius}km de ${munName}.`
                        }
                        hashtags={"destinodocente,SIPRI"}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Form>
            {loading && (!markers || markers.length === 0) && (
              <div className="mask text-center">
                <Spin
                  indicator={antIcon}
                  style={{ marginTop: "45vh", color: "#fff" }}
                />
              </div>
            )}
            <Row>
              <Col md={10}>
                <CenterDistancesList
                  markers={markers}
                  munCode={munCode}
                  munName={munName}
                  munCoords={coords}
                  aproxCoords={aproxCoords}
                  setMarkers={setMarkers}
                  updateMarkers={updateMarkers}
                  onCenterClick={onCenterClick}
                  positionCode={pPositionCode}
                  positionName={pPositionName}
                  loading={loading}
                />
              </Col>
              <Col md={14} className="map-container">
                <Map
                  centerCoords={defaultMapCenterCoords} // centro de España
                  markers={markers}
                  radius={radius}
                  coords={coords} // municipio
                  munCode={munCode}
                  showCenter={showMapCenter}
                  aproxCoords={aproxCoords}
                />
              </Col>
            </Row>
          </BrowserView>

          {/* MOBILE */}

          <MobileView>
            <Form name="search-form" className="px-2">
              <Row gutter={[12, 4]}>
                <Col xs={24}>
                  <Form.Item>
                    <div ref={refMun}>
                      <MunicipalityAutocomplete
                        onSelectMunicipalities={onSelectMunicipalities}
                        placeHolder="Municipio donde vives"
                        defaultValue={munName}
                        visiblePopOver={false}
                        onClearMunicipalities={onClearMunicipalities}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={6} className="d-none">
                  <Tooltip title="Más opciones de búsqueda: denominación y radio">
                    <Button
                      onClick={onClicShowExtraFields}
                      style={{
                        padding: "0",
                        width: "100%",
                        height: "100%",
                      }}
                      type="button"
                      className="btn-advanced small"
                      icon={<ControlOutlined />}
                    >
                      Filtros
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              {showExtraFields && (
                <Row
                  gutter={[10, 8]}
                  className={`advanced-search ${showExtraFields ? "show" : ""}`}
                >
                  <Col xs={10}>
                    <Form.Item className="mb-1">
                      <div ref={refDenom}>
                        <Select
                          onChange={onChangeDenom}
                          defaultValue={
                            pCentersDenom
                              ? getValueLabel(optionsDenom, pCentersDenom)
                              : "Instituto de Educación Secundaria"
                          }
                          options={optionsDenom}
                          disabled={disabled}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={14}>
                    <Form.Item className="mb-1">
                      <div ref={refRadius}>
                        <SliderMobile
                          min={10}
                          step={null}
                          marks={distanceMarks}
                          defaultValue={pFromRadius ? pFromRadius : radius}
                          onChange={onChangeSlider}
                          disabled={disabled}
                          style={{
                            "--fill-color": "#26400C",
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
            <Tabs className="result-tab">
              <Tabs.Tab
                title={
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      maxWidth: "70vw",
                    }}
                  >
                    {markers?.length > 0
                      ? markers.length + " cerca de " + munName
                      : "Centros"}
                    {/*markers?.length > 0 && shareWS("rgb(0,168,132)", 18)*/}
                  </span>
                }
                key="list"
              >
                <CenterDistancesList
                  markers={markers}
                  munCode={munCode}
                  munName={munName}
                  munCoords={coords}
                  aproxCoords={aproxCoords}
                  setMarkers={setMarkers}
                  updateMarkers={updateMarkers}
                  onCenterClick={onCenterClick}
                  positionCode={pPositionCode}
                  positionName={pPositionName}
                  loading={loading}
                />
              </Tabs.Tab>
              <Tabs.Tab title="Mapa" key="map">
                <div className="map-container">
                  <Map
                    centerCoords={defaultMapCenterCoords}
                    markers={markers}
                    radius={radius}
                    coords={coords}
                    munCode={munCode}
                    showCenter={showMapCenter}
                    aproxCoords={aproxCoords}
                  />
                </div>
              </Tabs.Tab>
            </Tabs>
            {markers?.length > 0 && (
              <div className="fixed-widgets" style={{ width: "50px", height: "145px", paddingTop: "5px" }}>
                <ShareWSButton
                  className={"p-2"}
                  color="rgb(0,168,132)"
                  size="30"
                  message={
                    "¡Míralo en Destino Docente! " +
                    pluralize(Object.keys(markers).length, "centro") +
                    ` (${getValueLabel(
                      optionsDenom,
                      denom
                    )}) a ${radius}km de ${munName}. ` +
                    `${CLIENT_SITE_URL}/centros/${coords[0]}/${coords[1]
                    }/${munName}/${munCode}/${denom}/${slugify(
                      getValueLabel(optionsDenom, denom)
                    )}/radio/${radius}`
                  }
                />
                <ShareFBButton
                  className={"p-2"}
                  color="#1877f2"
                  size="28"
                  url={`${CLIENT_SITE_URL}/centros/${coords[0]}/${coords[1]
                    }/${munName}/${munCode}/${denom}/${slugify(
                      getValueLabel(optionsDenom, denom)
                    )}/radio/${radius}`}
                  message={
                    "¡Míralo en Destino Docente! " +
                    pluralize(Object.keys(markers).length, "centro") +
                    ` (${getValueLabel(
                      optionsDenom,
                      denom
                    )}) a ${radius}km de ${munName}.`
                  }
                />
                <ShareXButton
                  className={"p-2"}
                  color="rgb(0,0,0)"
                  size="26"
                  url={`${CLIENT_SITE_URL}/centros/${coords[0]}/${coords[1]
                    }/${munName}/${munCode}/${denom}/${slugify(
                      getValueLabel(optionsDenom, denom)
                    )}/radio/${radius}`}
                  message={
                    "¡Míralo en Destino Docente! " +
                    pluralize(Object.keys(markers).length, "centro") +
                    ` (${getValueLabel(
                      optionsDenom,
                      denom
                    )}) a ${radius}km de ${munName}.`
                  }
                  hashtags={"destinodocente,SIPRI"}
                />
              </div>
            )}
          </MobileView>
        </Content>
        <Footer className="footer d-none"></Footer>
      </Layout>

      <Drawer
        title={
          <>
            <span className="me-3">
              {munName} <FaArrowRight size={12} />{" "}
              {denomAbrev[selectedCenter?.denom__name]} "{selectedCenter?.name}"
            </span>
            <CenterDistance
              idCenter={selectedCenter?.id}
              centerCoords={[
                selectedCenter?.latitude,
                selectedCenter?.longitude,
              ]}
              munCode={munCode}
              munCoords={coords}
              showInfo={true}
              aproxCoords={aproxCoords}
            />
          </>
        }
        open={isModalOpened && !isMobile}
        getContainer={false} // para que funcione en mobile
        onClose={() => {
          setIsModalOpened(false);
        }}
        width={"41.666666%"}
        style={{ backgroundColor: "#EBF2EE" }}
      >
        <div className="blog-lead my-2 pull-right d-none">
          <span className="me-3">Desde {munName}</span>
          <CenterDistance
            idCenter={selectedCenter?.id}
            centerCoords={[selectedCenter?.latitude, selectedCenter?.longitude]}
            munCode={munCode}
            munCoords={coords}
            showInfo={true}
            aproxCoords={aproxCoords}
          />
        </div>
        <CenterMap
          coords={[selectedCenter?.latitude, selectedCenter?.longitude]}
          height={180}
          showMarker={true}
        />
        <div className="mt-2 mb-2 mild-lead">
          <i className="fa fa-map-signs" aria-hidden="true"></i>
          {"  "}
          {selectedCenter?.address}.{"  "}
          {selectedCenter?.zip_code} <b>{selectedCenter?.city}</b>,{" "}
          {selectedCenter?.province}
          <br />
          <FaAt />
          {"  "}
          <a
            href={
              "mailto:" +
              selectedCenter?.email +
              "?body=Encontrado en destinodocente.es"
            }
            target="_blank"
            rel="noreferrer"
          >
            <span>{selectedCenter?.email}</span>
          </a>
          <br />
          <FaPhone />
          {"  "}
          <a
            href={"callto:" + selectedCenter?.phone}
            target="_blank"
            rel="noreferrer"
          >
            <span>{selectedCenter?.phone}</span>
          </a>
          <br />
          Código de centro {selectedCenter?.code}
        </div>
        <div className="my-4">
          {!!coords && (
            <>
              {/*
              <TravelButton
                munCodeOrig={munCode}
                munNameOrig={munName}
                centerDest={selectedCenter}
                userId={getLocalUserId()}
                iconSize={38}
                className="ps-0 mt-1 me-3"
                title="Publica un viaje a este centro"
              />
              <AdButton
                munCode={selectedCenter?.municipality_code}
                munName={selectedCenter?.municipality}
                centerCode={selectedCenter?.code}
                centerName={selectedCenter?.name}
                userId={getLocalUserId()}
                className="p-0 me-2"
                iconSize={34}
                title="Publica un anuncio cerca de este centro"
              />
              */}
              <LoginButton
                className="mt-1"
                title="Ruta en Google Maps"
                from={
                  "/redirect?url=https://www.google.es/maps/dir/" +
                  coords +
                  "/" +
                  selectedCenter?.latitude +
                  "," +
                  selectedCenter?.longitude +
                  "?travelmode=driving"
                }
              >
                <FaDirections size={28} />
              </LoginButton>
            </>
          )}
          <ShareWSButton
            color="rgb(0,168,132)"
            size="34"
            message={
              "¡Míralo en Destino Docente! " +
              `${selectedCenter?.denom} "${selectedCenter?.name}"` +
              ` https://destinodocente.es/centros${selectedCenter?.absolute_url}`
            }
            className={" "}
          />
          <ShareFBButton
            color="#1877f2"
            size="32"
            url={`https://destinodocente.es/centros/${selectedCenter?.code}`}
            message={
              "¡Míralo en Destino Docente! " +
              `${selectedCenter?.denom} "${selectedCenter?.name}"`
            }
            className={" "}
          />
        </div>
        <div className="my-4">
          <CenterTempPositions code={selectedCenter?.code} />
          <TravelsMunCenter
            munCodeOrig={munCode}
            munNameOrig={munName}
            munCodeDest={selectedCenter?.municipality__code}
            centerDest={selectedCenter}
            schedule={selectedCenter?.denom__id}
          />
          <AdsMun
            munCode={selectedCenter?.municipality__code}
            munName={selectedCenter?.municipality__name}
            center={selectedCenter}
          />
          {/*
          <CenterTrasladosPositions code={selectedCenter?.code} />
          <CenterInitialPositions code={selectedCenter?.code} />
           */}
        </div>
      </Drawer>

      <Popup
        visible={isModalOpened && !!isMobile}
        getContainer={false} // para que funcione en mobile
        onMaskClick={() => setIsModalOpened(false)}
        position="right"
        bodyStyle={{ width: "100vw", backgroundColor: "#EBF2EE" }}
      >
        <NavBar
          onBack={() => {
            setIsModalOpened(false);
          }}
          className="back-bar"
          style={{ height: "64px" }}
        >
          <Text
            style={{
              whiteSpace: "normal" /* Permite saltos de línea */,
              wordBreak: "break-word" /* Rompe las palabras largas */,
              color: "white",
              fontSize: "1em",
            }}
          >
            {munName} <FaArrowRight /> {denomAbrev[selectedCenter?.denom__name]}{" "}
            {selectedCenter?.name}{" "}
            <CenterDistance
              idCenter={selectedCenter?.id}
              centerCoords={[
                selectedCenter?.latitude,
                selectedCenter?.longitude,
              ]}
              munCode={munCode}
              munCoords={coords}
              showInfo={true}
              aproxCoords={aproxCoords}
            />
          </Text>
        </NavBar>
        <div
          id="scrollableDiv"
          style={{
            height: "100%",
            overflow: "auto",
          }}
          className="ps-2 pe-1"
        >
          <h5 className="pt-2 d-none">
            {selectedCenter?.code} - {denomAbrev[selectedCenter?.denom__name]} "
            {selectedCenter?.name}"
          </h5>
          <div style={{ height: "240px" }} className="mb-2">
            <CenterMap
              coords={[selectedCenter?.latitude, selectedCenter?.longitude]}
            />
          </div>

          <div className="mb-2 mt-1">
            <i className="fa fa-map-signs" aria-hidden="true"></i>
            {"  "}
            {selectedCenter?.address}.{"  "}
            <br />
            {selectedCenter?.zip_code} <b>{selectedCenter?.city}</b>,{" "}
            {selectedCenter?.province}
            <br />
            <FaAt />
            {"  "}
            <a
              href={
                "mailto:" +
                selectedCenter?.email +
                "?body=Encontrado en destinodocente.es"
              }
              target="_blank"
              rel="noreferrer"
            >
              <small>{selectedCenter?.email}</small>
            </a>
            <br />
            <FaPhone />
            {"  "}
            <a
              href={"callto:" + selectedCenter?.phone}
              target="_blank"
              rel="noreferrer"
            >
              <small>{selectedCenter?.phone}</small>
            </a>
            <br />
            Código de centro: {selectedCenter?.code}
          </div>
          <Card className=" m-4 mild-lead text-center d-none">
            {munName} <FaArrowRight /> {denomAbrev[selectedCenter?.denom__name]}{" "}
            {selectedCenter?.name} <br />
            <CenterDistance
              idCenter={selectedCenter?.id}
              centerCoords={[
                selectedCenter?.latitude,
                selectedCenter?.longitude,
              ]}
              munCode={munCode}
              munCoords={coords}
              showInfo={true}
              aproxCoords={aproxCoords}
            />{" "}
          </Card>
          <div className="py-2 mt-4 text-center">
            {!!coords && (
              <>
                {/*             
                <TravelButton
                  munCodeOrig={munCode}
                  munNameOrig={munName}
                  centerDest={selectedCenter}
                  userId={getLocalUserId()}
                  iconSize={38}
                  className="mt-1 me-2"
                  title="Publica un viaje a este centro"
                />
                <AdButton
                  munCode={selectedCenter?.municipality_code}
                  munName={selectedCenter?.municipality}
                  centerCode={selectedCenter?.code}
                  centerName={selectedCenter?.name}
                  userId={getLocalUserId()}
                  className="p-0 me-3"
                  iconSize={34}
                  title="Publica un anuncio cerca de este centro"
                />
                 */}
                <LoginButton
                  className="p-0 me-3"
                  title="Ruta en Google Maps"
                  from={
                    "/redirect?url=https://www.google.es/maps/dir/" +
                    coords +
                    "/" +
                    selectedCenter?.latitude +
                    "," +
                    selectedCenter?.longitude +
                    "?travelmode=driving"
                  }
                >
                  <FaDirections size={28} style={{ color: "#26400C" }} />
                </LoginButton>
              </>
            )}
            <ShareWSButton
              color="rgb(0,168,132)"
              size="34"
              message={
                "¡Míralo en Destino Docente! " +
                `${selectedCenter?.denom} "${selectedCenter?.name}"` +
                ` https://destinodocente.es/centros${selectedCenter?.absolute_url}`
              }
            />
            <ShareFBButton
              className={"ps-3"}
              color="#1877f2"
              size="32"
              url={`https://destinodocente.es/centros${selectedCenter?.absolute_url}`}
              message={
                "¡Míralo en Destino Docente! " +
                `${selectedCenter?.denom} "${selectedCenter?.name}"`
              }
            />
          </div>
          <div className="mt-4">
            <CenterTempPositions code={selectedCenter?.code} />
            <TravelsMunCenter
              munCodeOrig={munCode}
              munNameOrig={munName}
              munCodeDest={selectedCenter?.municipality__code}
              centerDest={selectedCenter}
              schedule={selectedCenter?.denom__id}
            />
            <AdsMun
              munCode={selectedCenter?.municipality__code}
              munName={selectedCenter?.municipality__name}
              center={selectedCenter}
            />
            <br />
            <br />
            <br />
            {/*
            <CenterTrasladosPositions
              code={selectedCenter?.code}
              height={240}
            />
            <CenterInitialPositions code={selectedCenter?.code} height={240} />
            */}
          </div>
        </div>
      </Popup>
    </>
  );
}

export default CentersPage;
