import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  FaTrash,
  FaUsers,
  //FaPencilAlt,
  FaAngleDown,
  FaAngleUp,
  FaPlus, FaBook
} from "react-icons/fa";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  notification,
  Input,
  Tooltip,
  Drawer,
  Card,
  Space as SP,
  Select
} from "antd";
import { Radio, Space, Popup, Stepper, NavBar } from "antd-mobile";
import { isMobile } from "react-device-detect";
import { ExclamationCircleOutlined, EditFilled } from "@ant-design/icons";
import MunicipalityAutocomplete from "../municipality-autocomplete";
import LoginButton from "../auth/login-button";
import ImageSelector from "../visual/image-selector";
import {
  API_ADS_URL,
  trailing_key,
  trailing_key_param,
  NEED_A_SYLLABUS,
  HAVE_A_SYLLABUS,
  NEED_A_TRAINER,
  I_AM_A_TRAINER,
  SYLLABUS,
  TRAINER
} from "../../constants";
import axiosAuth from "../utils/auth-interceptor";
import { getLocalUserId, isAuth } from "../../services/auth-services";
import { SpecialityAutocomplete, SpecialitySelect } from "../positions/speciality-autocomplete";
import { optionRegions } from "../../constants/data";

import background from "../../assets/background-opos.jpg";

const { confirm } = Modal;
const { TextArea } = Input;


const AdButtonSyllabus = (props) => {
  const [form] = Form.useForm();
  const [formPre] = Form.useForm();
  const [mainType, setMainType] = useState(null); // no es param del form
  //const [ad, setAd] = useState(null);
  const [munCode, setMunCode] = useState(null);
  const [munCodeError, setMunCodeError] = useState("");
  const [munName, setMunName] = useState(null);
  //const [centerCode, setCenterCode] = useState(null);
  //const [centerName, setCenterName] = useState(null);
  const [price, setPrice] = useState(null);
  const [priceError, setPriceError] = useState("");
  const [adType, setAdType] = useState("");
  const [adSubType, setAdSubType] = useState("");
  //const [description, setDescription] = useState(""); 
  //const [title, setTitle] = useState("");
  const [nRooms, setNRooms] = useState(1);
  const [nBaths, setNBaths] = useState(1);
  const [specialityCode, setSpecialityCode] = useState(null);
  const [specialityName, setSpecialityName] = useState(null);
  const [regionCode, setRegionCode] = useState(null)
  const [regionName, setRegionName] = useState(null)
  const [regions, setRegions] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(props.open ? props.open : false);
  const [isPopOpened, setIsPopOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [extUrl, setExtUrl] = useState("");


  let navigate = useNavigate();

  /**
   * Anuncio de Walla
   * @param {*} url
   */
  const fetchExtData = (url) => {
    let uri = `${API_ADS_URL}/ext-ad/?url=${url}${trailing_key_param}`;
    axios
      .get(uri)
      .then((response) => {
        if (response.data) {
          notification.success({
            message: "Hemos obtenido la información de tu anuncio.",
            description: "¡Revisa los datos y publica tu anuncio!",
          });
          //setTitle(response.data.title);
          setPrice(response.data.price);
          setAdType(response.data.ad_type);
          setAdSubType(response.data.ad_sub_type);
          form.setFieldsValue({
            description: response.data.description,
          });
          if (response.data.municipality) {
            setMunCode(response.data.municipality.code);
            setMunName(response.data.municipality.name);
          }
          setNRooms(response.data.n_rooms);
          setNBaths(response.data.n_baths);
          setExtUrl(url);
        }
      })
      .catch((error) => {
        notification.warning({
          message: "¡Vaya! no hemos podido recuperar los datos de tu anuncio.",
          description:
            "Revisa el enlace de tu anuncio o rellena manualmente la información",
        });
      });
  };

  const openModal = () => {
    if (!props.ad)
      resetState();
    setIsModalOpened(true);
  };

  const closeModal = () => {
    //if (!props.ad) resetState();
    setIsModalOpened(false);
    Modal.destroyAll();
  };

  useEffect(() => {
    if (props.munCode) setMunCode(props.munCode);
  }, [props.munCode]);

  useEffect(() => {
    if (props.munName) setMunName(props.munName);
  }, [props.munName]);
  /*
    useEffect(() => {
      if (props.centerCode) setCenterCode(props.centerCode);
    }, [props.centerCode]);
  
    useEffect(() => {
      if (props.centerName) setCenterName(props.centerName);
    }, [props.centerName]);
    */
  useEffect(() => {
    if (props.regionName) setRegionName(props.regionName);
  }, [props.regionName])

  useEffect(() => {
    if (props.specialityName) setSpecialityName(props.centerName);
  }, [props.specialityName])

  // para editar
  useEffect(() => {
    if (props.ad) {
      console.log("Ad", props.ad)
      setPrice(props.ad.price || null);
      setMainType(props.ad.ad_type == NEED_A_SYLLABUS || props.ad.ad_type == HAVE_A_SYLLABUS ? SYLLABUS : TRAINER)
      setAdType(props.ad.ad_type || "");
      setAdSubType(props.ad.ad_sub_type || "");
      //setDescription(props.ad.description || "");
      setMunCode(props.ad.municipality?.code || null);
      setMunName(props.ad.municipality?.name || "");
      //setCenterCode(props.ad.centers?.[0]?.code || null);
      //setCenterName(props.ad.centers?.[0]?.name || "");
      setRegions(props.ad.regions?.[0].id || null);
      setRegionCode(props.ad.regions?.[0]?.code || null);
      setRegionName(props.ad.regions?.[0]?.name || "");
      setSpecialityCode(props.ad.specialities?.[0]?.code || null);
      setSpecialityName(props.ad.specialities?.[0]?.name || "");
      setNRooms(props.ad.n_rooms || 1);
      setNBaths(props.ad.n_baths || 1);
      setFileList(props.ad.images || []);
      // textarea no va bien con states
      form.setFieldsValue({
        description: props.ad.description,
      });
    }/* else {
      if (!props.munName) {
        resetState();
      }
    }*/
  }, [props.ad]);

  const resetState = () => {
    setPrice(null); // Valor por defecto
    setMainType(null)
    setAdType(null);
    //setAdSubType("");
    //setDescription("");
    setMunCode(null);
    setMunName(null);
    //setCenterCode(null);
    //setCenterName(null);
    setRegionCode(null);
    setRegionName(null);
    setSpecialityCode(null);
    setSpecialityName(null);
    setRegions(null)
    //setNRooms(1);
    //setNBaths(1);
    setFileList([]);
    form.setFieldsValue({
      description: "",
    });
    formPre.setFieldsValue({
      url: "",
    });
    setCurrent(0);
    setPriceError("");
    setMunCodeError("");
    setExtUrl("");
  };


  const onChangeMainType = (value) => {
    setMainType(value);
    setAdType(null);
  };

  const onChangeType = (value) => {
    setAdType(value);
  };
  /*
  const onChangeSubType = (value) => {
    setAdSubType(value);
  };
  */
  const onSelectMunicipalities = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const code = children[2].props.children;
      setMunCode(code); // munCode
      setMunName(value);
      setMunCodeError("");

      form.setFieldsValue({ munCode: code });
    }
  };

  const onClearMunicipalities = () => {
    setMunCode(null);
    setMunName(null);
    setMunCodeError("");
    form.setFieldsValue({ munCode: null });
  };
  /*
  const onSelectCenters = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const code = children[2].props.children;
      setCenterCode(code);
      setCenterName(value);
    }
  };

  const onClearCenters = () => {
    setCenterCode(null);
    setCenterName(null);
  };
  */
  const onSelectSpecialities = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const code = children[2].props.children;
      setSpecialityCode(code);
      setSpecialityName(value);
    }
  };

  const onClearSpecialities = () => {
    setSpecialityCode(null);
    setSpecialityName(null);
  };

  const onSelectRegions = async (value) => {
    setRegions(value)
    if (value !== undefined) {
      setRegionName(value)
    }
  };

  const onClearRegions = () => {
    setRegionCode(null);
    setRegionName(null);
    setRegions(null)
  };


  const onFinish = async (values) => {
    setLoading(true);
    setPriceError("");
    setMunCodeError("");
    let uri;
    let errorMessages = [];

    const formData = new FormData();
    if (props.ad?.id) formData.append("adId", props.ad.id);
    if (values.description) formData.append("description", values.description); // con state no funciona bien
    if (regions)
      formData.append("regions", regions); // es un Select, no funciona igual que los autocomplete
    else {
      errorMessages.push("Indica una comunidad autonoma.\n");
    }
    if (adType)
      formData.append("adType", adType);
    else {
      errorMessages.push("Indica que necesitas.\n");
    }
    //formData.append("adSubType", adSubType);
    if (extUrl != "")
      formData.append("extUrl", extUrl);
    if (mainType == TRAINER)
      if (munCode) {
        formData.append("munCode", munCode);
      } else {
        errorMessages.push("Indica un municipio. ");
        setMunCodeError("Indica un municipio");
      }
    /*   
    if (centerCode != null && centerCode !== "")
      formData.append("centers", centerCode);
    */
    if (specialityCode)
      formData.append("specialities", specialityCode);
    else {
      errorMessages.push("Indica una especialidad\n");
    }
    if (adType == HAVE_A_SYLLABUS) {
      if (price != null && price > 0) formData.append("price", price);
      else {
        errorMessages.push("El precio tiene que ser mayor que 0. ");
        setPriceError("El precio tiene que ser mayor que 0");
      }
      /*
      if (adSubType != "R") {
        if (nRooms != null && nRooms !== "") {
          formData.append("nRooms", parseInt(nRooms, 10));
        }
        if (nBaths != null && nBaths !== "") {
          formData.append("nBaths", parseInt(nBaths, 10));
        }
      }
      */
    }

    fileList.forEach((file) => {
      formData.append("images", file.originFileObj || file);
    });
    // errores: el form no es controlado, hay que hacer los errores manual
    if (errorMessages.length > 0) {
      notification.error({
        message: errorMessages,
      });
      setLoading(false);
      return;
    }
    try {
      // Determinar la URI
      if (props.ad) {
        uri = `${API_ADS_URL}/auth/update-by-params/${trailing_key}`;
      } else {
        uri = `${API_ADS_URL}/auth/create-by-params/${trailing_key}`;
      }
      console.log(formData);
      // Enviar la solicitud con FormData
      const response = await axiosAuth.post(uri, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Manejar la respuesta
      if (response) {
        notification.success({
          message: "El anuncio se ha guardado correctamente",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al guardar el anuncio",
        });
        console.log(error);
      }
    } finally {
      if (props) {
        if (typeof props.afterFinish === "function") {
          try {
            props.afterFinish();
          } catch (error) {
            console.error("afterFinish not defined", error);
          }
        }
      }
      setLoading(false);
      setIsModalOpened(false);
      if (!loading)
        resetState();
    }
  };

  // anuncios walla
  const onFinishPre = async (values) => {
    setCurrent(current + 1);
    if (values.url) fetchExtData(values.url);
  };

  const onClickDelete = () => {
    confirm({
      title: "¿Quieres eliminar este anuncio?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk: () => handleDelete(),
      onCancel: () => Modal.destroyAll(),
      footer: [
        <Button onClick={() => Modal.destroyAll()}>Cancelar</Button>,
        <Button onClick={handleDelete} className="danger ms-2" danger>
          Aceptar
        </Button>,
      ],
    });
  };

  const handleDelete = async () => {
    try {
      const uri = `${API_ADS_URL}/${props.ad.id}/${trailing_key}`;
      const response = await axiosAuth.delete(uri);
      if (response) {
        //setAd(null);
        notification.success({
          message: "El anuncio se ha eliminado correctamente",
          description: "",
        });
        Modal.destroyAll();
      }
    } catch (error) {
      if (error.response.status === 401) {
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al elimnar el anuncio",
        });
      }
    }
  };

  const AdFormPopUp = () => {
    return (
      <Form
        form={form}
        scrollToFirstError
        onFinish={onFinish}
        style={{
          textAlign: "left",
        }}
      >
        <Row gutter={[8, 8]}>
          <Col>
            <h6>¿Qué buscas?</h6>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Por favor indica lo que necesitas",
                },
              ]}
            >
              <Radio.Group onChange={onChangeMainType} value={mainType}>
                <Space direction="vertical">
                  <Radio value={SYLLABUS}>
                    <FaBook
                      className="mx-2"
                      style={{ color: "LimeGreen" }}
                    />
                    <span className="mx-2" style={{ color: "#000" }}>
                      Temario
                    </span>
                  </Radio>
                  <Radio value={TRAINER}>
                    <FaUsers className="mx-2" style={{ color: "darkred" }} />
                    <span className="mx-2" style={{ color: "#000" }}>
                      Preparador
                    </span>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        {!!mainType && (
          <Row gutter={[8, 8]}>
            <Col>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Por favor indica si buscas u ofreces temario o preparador",
                  },
                ]}
              >
                <h6>Qué necesitas</h6>
                <Radio.Group
                  onChange={onChangeType}
                  value={adType}
                  style={{ width: '100%' }}
                >
                  <Space direction="vertical">
                    {mainType === SYLLABUS && (
                      <Radio value={HAVE_A_SYLLABUS} style={{ marginBottom: 8 }}>
                        <FaBook className="mx-2" style={{ color: 'LimeGreen' }} />
                        <span className="mx-2" style={{ color: '#000' }}>
                          Vendo temario
                        </span>
                      </Radio>
                    )}
                    {mainType === SYLLABUS && (
                      <Radio value={NEED_A_SYLLABUS} style={{ marginBottom: 8 }}>
                        <FaBook className="mx-2" style={{ color: 'LimeGreen' }} />
                        <span className="mx-2" style={{ color: '#000' }}>
                          Compro temario
                        </span>
                      </Radio>
                    )}
                    {mainType === TRAINER && (
                      <Radio value={NEED_A_TRAINER} style={{ marginBottom: 8 }}>
                        <FaUsers className="mx-2" style={{ color: 'darkred' }} />
                        <span className="mx-2" style={{ color: '#000' }}>
                          Busco preparador
                        </span>
                      </Radio>
                    )}
                    {mainType === TRAINER && (
                      <Radio value={I_AM_A_TRAINER} style={{ marginBottom: 8 }}>
                        <FaUsers className="mx-2" style={{ color: 'darkred' }} />
                        <span className="mx-2" style={{ color: '#000' }}>
                          Soy preparador
                        </span>
                      </Radio>
                    )}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
        {mainType === TRAINER && (
          <>
            <Row gutter={[8, 8]}>
              <Col>
                <h6 className="mb-4">
                  ¿Dónde estás?
                </h6>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item
                  validateStatus={munCodeError ? "error" : ""}
                  help={munCodeError || ""}
                >
                  <MunicipalityAutocomplete
                    autoFocus={false}
                    onSelectMunicipalities={onSelectMunicipalities}
                    onClearMunicipalities={onClearMunicipalities}
                    defaultValue={munName}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Por favor indica la especialidad",
                },
              ]}
            >
              <h6>¿Qué especialidad?</h6>
              <SpecialityAutocomplete
                defaultValue={specialityName}
                onSelectSpecialities={onSelectSpecialities}
                onClearSpecialities={onClearSpecialities}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Por favor indica la comunidad autónoma",
                },
              ]}
            >
              <h6>¿De que comunidad?</h6>
              <Select
                onChange={onSelectRegions}
                onClear={onClearRegions}
                options={optionRegions}
                placeholder="Selecciona una comunidad"
                defaultValue={regionName}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          {adType && adType == HAVE_A_SYLLABUS && (
            <>
              <Col span={24}>
                <h6>¿Cuál es su precio?</h6>
                <Form.Item
                  validateStatus={priceError ? "error" : ""}
                  help={priceError || ""}
                >
                  <Stepper
                    value={price}
                    min={0}
                    formatter={(value) => `€ ${value}`}
                    parser={(text) => parseFloat(text.replace("€", ""))}
                    step={10}
                    onChange={(value) => {
                      setPrice(value);
                      if (value && priceError) setPriceError("");
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <h6>Añade fotos</h6>
                <Form.Item>
                  <ImageSelector files={fileList} setFiles={setFileList} />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <h6>Escribe algo más que quieras indicar</h6>
            <Form.Item name="description">
              <TextArea
                placeholder="Otra información (opcional)"
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
          <Col>
            <div className="button-group">
              {!!isAuth() && props.ad?.creator?.id === getLocalUserId() && (
                <Button
                  danger
                  onClick={onClickDelete}
                  className="delete-button"
                >
                  <FaTrash className="me-2" /> Eliminar
                </Button>
              )}
              <Button
                onClick={() => {
                  closeModal();
                }}
                className="me-2 d-none d-sm-inline"
              >
                Cancelar
              </Button>
              <Button
                htmlType="submit"
                loading={loading}
                className="button d-xs-block"
              >
                Publicar
              </Button>
            </div>
            <br />
          </Col>
        </Row>
      </Form>
    );
  };

  const AdPrevFormPopUp = () => {
    return (
      <Form form={formPre} onFinish={onFinishPre}>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <h5>¿Tienes tu anuncio en Wallapop?</h5>
            <p>
              <span>
                1. Copia el texto para compartir anuncio en Wallapop con el
                botón{" "}
              </span>
              <i
                className="fa-solid fa-arrow-up-from-bracket"
                style={{ fontSize: 18 }}
              ></i>{" "}
              <br />
              <br />
              <span>2. Pégalo en la caja de texto y pulsa "Continuar".</span>
            </p>

            <SP.Compact style={{ width: "100%" }}>
              <Form.Item name="url" style={{ flexGrow: 1 }}>
                <Input placeholder="Pega el texto Wallapop" allowClear />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Continuar
              </Button>
            </SP.Compact>
          </Col>
        </Row>
        <Row className="d-none">
          <Col>
            <p>
              <span>3. Completa la información y publica tu anuncio.</span>
            </p>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      {!!isAuth() ? (
        <>
          {!props.open &&
            <Tooltip title={props.title}>
              <Button
                //type="button"
                className={props.className ? props.className : "btn-action-plus"}
                onClick={() => {
                  openModal();
                }}
              >
                {!!props.icon ?
                  props.icon
                  :
                  props.ad ? (
                    <EditFilled />
                  ) : (
                    <FaPlus color={props.color} />
                  )
                }
                {!!props.children && props.children}
                {!!props.text && props.text}
              </Button>
            </Tooltip>
          }

          <Drawer
            open={isModalOpened && !isMobile}
            footer={null}
            onClose={() => {
              closeModal();
            }}
            width={"33%"}
            title={!!props.ad ? "Edita tu anuncio" : "Publica tu anuncio"}
            styles={{ body: { height: "100px" } }}
          >
            {!props.ad ? (
              <>
                <AdFormPopUp />
                <div className="contenedor-formulario d-none">
                  <div
                    key={0}
                    className={`formulario ${0 === current
                      ? "activo"
                      : 0 < current
                        ? "anterior"
                        : "siguiente"
                      }`}
                  >
                    <Card style={{ backgroundColor: "#EBF2EE" }}>
                      <AdPrevFormPopUp />
                    </Card>
                    <div className="text-center">
                      <Button
                        onClick={() => setCurrent(current + 1)}
                        className="p-0 mt-4 lead"
                        type="button"
                      >
                        Si no tienes anuncio en Wallapop,
                        <br /> empieza a publicar pulsando en la flecha
                        <br />
                        <FaAngleDown size={"32px"} className="mt-4" />
                      </Button>
                    </div>
                  </div>

                  <div
                    key={1}
                    className={`formulario pb-4 ${1 === current
                      ? "activo"
                      : 1 < current
                        ? "anterior"
                        : "siguiente"
                      }`}
                  >
                    {!props.ad && (
                      <div className="text-center">
                        <Button
                          onClick={() => setCurrent(current - 1)}
                          className="p-0"
                          type="button"
                        >
                          <FaAngleUp size={32} />
                        </Button>
                      </div>
                    )}
                    <AdFormPopUp />
                  </div>
                </div>
              </>

            ) : (
              <AdFormPopUp />
            )}
          </Drawer>

          <Popup
            visible={isModalOpened && isMobile}
            getContainer={false} // para que funcione en mobile
            onMaskClick={() => {
              closeModal();
            }}
            bodyStyle={{ width: "100%" }}
            position="right"
          >
            <NavBar
              onBack={() => {
                closeModal();
              }}
              className="back-bar"
              style={{ height: "64px" }}
            >
              {!!props.ad ? "Edita tu " : "Publica tu "}anuncio
            </NavBar>
            {!props.ad && (
              <div className="py-3 d-none" style={{ backgroundColor: "#EBF2EE" }}>
                <strong>
                  Si tienes anuncio en Wallapop, tráelo a Destino Docente
                </strong>
                <Button
                  onClick={() => setIsPopOpened(true)}
                  type="primary"
                  className="m-2"
                >
                  Traer mi anuncio
                </Button>
              </div>
            )}
            <div
              className="p-3 overflow"
              style={{
                minHeight: "400px",
                textAlign: "left",
              }}
            >
              <AdFormPopUp />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </Popup>
          <Popup
            getContainer={false} // para que funcione en mobile
            visible={isPopOpened}
            onMaskClick={() => {
              setIsPopOpened(false);
            }}
          >
            <Card style={{ backgroundColor: "#EBF2EE" }} className="m-2">
              <AdPrevFormPopUp />
            </Card>
          </Popup>
        </>
      ) : (
        <>
          <Tooltip title="Inicia sesión y publica tu anuncio">
            <Button
              type="button"
              className={props.className ? props.className : "btn-action-plus"}
              onClick={() => {
                openModal();
              }}
              icon={<FaPlus />}
            >
              {!!props.children && props.children}
              {props.text}
            </Button>
          </Tooltip>

          <Modal
            open={isModalOpened && !isMobile}
            footer={null}
            onCancel={() => {
              closeModal();
            }}
          >
            <h4 className="mb-3">Publica tu anuncio</h4>
            <img
              alt="Anuncios Destino Docente"
              src={background}
              style={{ maxWidth: "100%", objectFit: "cover" }}
            />
            <p className="lead mt-4">
              ¿Buscas temario, temario o preparador? ¿Has aprobado y quieres vender tu
              material?
            </p>
            <p>
              Inicia sesión, publica tu anuncio y contacta con otros usuarios.
            </p>
            <div className="text-center">
              <LoginButton from={window.location.pathname} className={"button"}>
                ¡Vamos!
              </LoginButton>
            </div>
          </Modal>

          <Popup
            visible={isModalOpened && isMobile}
            getContainer={false} // para que funcione en mobile
            onMaskClick={() => {
              closeModal();
            }}
            bodyStyle={{ height: "75vh" }}
          >
            <img
              alt="Anuncios Destino Docente"
              src={background}
              style={{ width: "100%", objectFit: "cover" }}
            />
            <div className="p-2">
              <h4 className="mt-1">Publica tu anuncio</h4>
              <p>
                <b>
                  ¿Buscas temario o preparador? ¿Has aprobado y quieres vender tu
                  material?
                </b>
                <br />
                Inicia sesión, publica tu anuncio y contacta con otros usuarios.
              </p>
              <LoginButton from={window.location.pathname} className={"button"}>
                ¡Vamos!
              </LoginButton>
            </div>
          </Popup>
        </>
      )}
    </>
  );
};

export default AdButtonSyllabus;
