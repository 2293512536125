import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Image, Skeleton, Carousel } from "antd";
import { NavBar } from "antd-mobile";
import {
  FaUsers,
  FaHouseUser,
  FaBuilding,
  FaBed,
  FaBath,
} from "react-icons/fa";
import slugify from "react-slugify";
import moment from "moment/moment";
import { Helmet } from "react-helmet-async";


import { API_ADS_URL, trailing_key, CLIENT_SITE_URL, HAVE_A_HOUSE, NEED_A_HOUSE } from "../../constants";
import { getAdTitle, getBaseUrlAd } from "../../utils/utils";
import { getLocalUserId, isAuth } from "../../services/auth-services";
import { denomAbrev } from "../../constants/data";
import { isMobile } from "react-device-detect";
import ShareMenu from "../../components/visual/share-menu";
import { ShareFBButton, ShareWSButton, ShareXButton } from "../../utils/utils";
import MessageButton from "../../components/messaging/message-button";
import TravelsMunCenter from "../../components/travels/travels-mun-center";
import FavButton from "../../components/visual/fav";
import CenterMap from "../../components/center-map";
import PageHeader from "../../layout/header";
import PageFooter from "../../layout/footer";
import SectionImage from "../../components/visual/section-image";

/**
 * AdInfo
 *
 * @component
 * @return {string} JSX for AdInfo
 */
function AdInfo() {
  const { adId: pAdId, adName: pAdName } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  let baseURL = "";

  const fetchData = async (id) => {
    const uri = `${API_ADS_URL}/${id}/${trailing_key}`;
    setLoading(true);
    try {
      const response = await axios.get(uri);
      setItem(response.data);
      console.log(response.data);
    } catch (error) {
      // Verificar si el error es un error de respuesta HTTP y si el código es 404
      if (error.response && error.response.status === 404) {
        console.error("Error AD 404: No encontrado.");
      } else {
        console.error("Error Ad", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const back = () => {
    const isDirectAccess =
      window.performance.getEntriesByType("navigation")[0].type === "navigate";

    if (isDirectAccess) {
      navigate(getBaseUrlAd(item))
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (pAdId) fetchData(pAdId);
  }, [pAdId]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={
          !!item ?
            `${CLIENT_SITE_URL}${getBaseUrlAd(item)}${item.absolute_url}`
            :
            `${CLIENT_SITE_URL}/compartir-casa/${item?.id
            }/${slugify(getAdTitle(item?.ad_title))}`} />
        <title>{"Destino Docente. " + item?.ad_title}</title>
        <meta
          name="description"
          content={item?.description}
        />
      </Helmet>
      {isMobile ? (
        <NavBar onBack={back} className="back-bar" style={{ height: "64px" }}>
          {item && (
            <>
              <h1 style={{ fontSize: "1.1rem" }}>
                {item?.ad_type === HAVE_A_HOUSE ? (
                  <FaHouseUser
                    size="18"
                    style={{ marginTop: "-2px", color: "LimeGreen" }}
                  />
                ) : (
                  item?.ad_type === NEED_A_HOUSE && (
                    <FaUsers
                      size="16"
                      style={{ marginTop: "-2px", color: "darkred" }}
                    />)
                )}
                <span className="ms-2">{item?.ad_title}</span>
              </h1>
            </>
          )}
        </NavBar>
      ) : (
        <>
          <PageHeader />
          <SectionImage
            title={
              <>
                {item?.ad_type === HAVE_A_HOUSE ? (
                  <FaHouseUser
                    size="18"
                    style={{ marginTop: "-2px", color: "LimeGreen" }}
                  />
                ) : (
                  item?.ad_type === NEED_A_HOUSE && (
                    <FaUsers
                      size="16"
                      style={{ marginTop: "-2px", color: "darkred" }}
                    />)
                )}
                <span className="ms-2">{item?.ad_title}</span>
              </>
            }
          /></>
      )}

      <div className="row mobile-row">
        <div className="col-md-10 offset-md-1 col-xs-12 text-page">
          {item ? (
            <>
              <Card
                style={{ width: "100%" }}
                actions={
                  item.creator.id != getLocalUserId() && [
                    <MessageButton
                      senderId={getLocalUserId()}
                      recipientId={item.creator.id}
                    >
                      <span className="ms-2">Contactar</span>
                    </MessageButton>,
                    isAuth() && (
                      <FavButton contentTypeId={44} objectId={item.id} />
                    ),
                  ]
                }
                extra={[
                  <ShareMenu title="Comparte este anuncio" key={"share-" + item.id}>
                    <ul className="share-menu">
                      <li key={item.id + "-1"} className="py-2">
                        <ShareWSButton
                          color="rgb(0,168,132)"
                          size="28"
                          message={
                            "¡Míralo en en Destino Docente! " +
                            `${item.ad_title}` +
                            ` https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`
                          }
                        >
                        </ShareWSButton>
                      </li>
                      <li key={item.id + "-2"} className="py-2">
                        <ShareFBButton
                          color="#1877f2"
                          size="26"
                          url={`https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`}
                          message={`¡Míralo en Destino Docente! ${item.ad_title} `}
                        >
                        </ShareFBButton>
                      </li>
                      <li key={item.id + "-3"} className="py-2">
                        <ShareXButton
                          color="#000000"
                          size="26"
                          url={`https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`}
                          message={`¡Míralo en Destino Docente! ${item.ad_title} `}
                          hashtags="destinodocente,oposiciones"
                        >
                        </ShareXButton>
                      </li>
                    </ul>
                  </ShareMenu>,
                ]}
                cover={
                  <>
                    <Carousel style={{ background: '#7EBF8E' }}>
                      {item.images.map((image, index) => (
                        <div key={index} className="mb-4">
                          <Image
                            src={image.url}
                            alt={`Foto ${index} del anuncio ${item.ad_title}`}
                            width="100%"
                            height={200}
                            style={{ objectFit: 'cover' }}
                          />
                        </div>
                      ))}
                      <div style={{ width: "100%" }}>
                        {!!item.centers && item.centers.length > 0 ? (
                          <CenterMap coords={[item.centers[0]?.latitude, item.centers[0]?.longitude]} height={200} draggable={false} zoom={13} showCircle={true} />
                        ) : (
                          <>
                            <CenterMap coords={[item.municipality?.latitude, item.municipality?.longitude]} height={200} draggable={false} zoom={10} />
                          </>
                        )}
                      </div>
                    </Carousel>
                  </>
                }
                className="mt-4"
              >
                <Row gutter={[8, 8]} className="mt-2">
                  {!!item.price && item.price > 0 && (
                    <Col md={24} xs={24}>
                      <h5>{Math.round(item.price).toString()} €</h5>
                    </Col>
                  )}
                  {!!item.description && (
                    <Col md={24} xs={24}>
                      <p>{item.description}</p>
                    </Col>
                  )}
                  <Col md={12} xs={24}>

                  </Col>
                  {!!item.n_rooms && (
                    <Col md={4} xs={4}>
                      <FaBed size={"20"} /> {item.n_rooms}
                    </Col>
                  )}
                  {!!item.n_baths && (
                    <Col md={4} xs={4}>
                      <FaBath size={"18"} /> {item.n_baths}
                    </Col>
                  )}
                  {item.municipality && (
                    <Col md={24} xs={24}>
                      <i className="fa fa-map-marker me-2" aria-hidden="true" />
                      {item.municipality.name} ({item.municipality.province})
                    </Col>
                  )}
                  {!!item.centers && item.centers.length > 0 && (
                    <Col md={16} xs={24}>
                      <i className="fa fa-map-marker " aria-hidden="true" />
                      {" Cerca de " +
                        denomAbrev[item.centers[0].denom] +
                        " " +
                        item.centers[0].name}
                    </Col>
                  )}
                  {!!item.regions && item.regions.length > 0 && (
                    <Col md={24} xs={24}>
                      Comunidad Autónoma:
                      <ul>
                        {item.regions.map((region, index) => (
                          <li key={index}>
                            {region.name}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  )}
                  {!!item.specialities && item.specialities.length > 0 && (
                    <Col md={24} xs={24}>
                      Especialidad
                      <ul>
                        {item.specialities.map((speciality, index) => (
                          <li key={index}>
                            {speciality.name}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  )}
                </Row>
                {item.creator.id == getLocalUserId() && (
                  <Row gutter={[8, 8]}>
                    <Col md={24} xs={24}>
                      <div className="pull-right">
                        <i>
                          {item?.created
                            ? moment(item?.created).format("D/M/YY")
                            : ""}
                        </i>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card>
              {!!item.centers && item.centers.length > 0 && (
                <TravelsMunCenter
                  munCodeOrig={null}
                  munNameOrig={null}
                  munCodeDest={item?.municipality_code}
                  munNameDest={item?.municipality}
                  centerDest={item?.centers[0]}
                  schedule={item?.centers[0]?.denom_id}
                />
              )}
            </>
          ) : (
            <div className="text-center my-4">
              {loading ? (
                <>
                  <Skeleton className="p-3 mt-3" active />
                </>
              ) : (
                <>
                  <Card
                    cover={
                      <div className="text-center mt-4">
                        <FaBuilding
                          size="72px"
                          style={{
                            color: "#444",
                          }}
                        />
                      </div>
                    }
                  >
                    <p className="text-center">
                      ¿Vaya! no se ha encontrado el anuncio que buscas.
                      {!!item?.ad_type == HAVE_A_HOUSE || item?.ad_type == NEED_A_HOUSE
                        ? <>Busca más anuncios en <Link to='/compartir-casa'>Compartir Casa</Link>.</>
                        : <>Busca más anuncios en <Link to='/oposiciones'>Oposiciones</Link>.</>}
                    </p>
                  </Card>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {!isMobile && (<PageFooter />)}
    </>
  );
}

export default AdInfo;
