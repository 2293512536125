import axios from "axios";
import { refreshToken } from "../../services/auth-services";

// Crear una instancia de Axios para configurar el interceptor
const axiosAuth = axios.create();

// Interceptor de solicitudes para prevenir el caché
axiosAuth.interceptors.request.use(
  (config) => {
    // Añade headers de Cache-Control a cada solicitud
    config.headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
    config.headers["Pragma"] = "no-cache";
    config.headers["Expires"] = "0";

    // Asegúrate de retornar la configuración modificada
    return config;
  },
  (error) => {
    // Manejo de errores de solicitud
    return Promise.reject(error);
  }
);

// Interceptor de respuestas para manejo de tokens expirados
axiosAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const data = await refreshToken(); // Devuelve el nuevo access_token
      //axios.defaults.headers.common["Authorization"] = `Bearer ${data}`; // Actualiza el token en los headers por defecto
      axiosAuth.defaults.headers.common["Authorization"] = `Bearer ${data}`; // Actualiza en axiosAuth
      originalRequest.headers["Authorization"] = `Bearer ${data}`; // Añade al request original
      return axiosAuth(originalRequest); // Usa axiosAuth para reintentar
    }
    return Promise.reject(error);
  }
);

export default axiosAuth;
