import React, { useState, useEffect } from "react";
import { List, Row, Col, Card, Typography, Skeleton, Image, Spin } from "antd";
import {
  FaBed,
  FaBath,
  FaExternalLinkAlt,
  FaBook,
} from "react-icons/fa";
import moment from "moment/moment";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { getChunkByKey } from "../../services/utils-services";
import { getLocalUserId } from "../../services/auth-services";
import {
  API_ADS_URL,
  api_key,
  CLIENT_SITE_URL,
  NEED_A_SYLLABUS,
  HAVE_A_SYLLABUS,
  NEED_A_TRAINER,
  I_AM_A_TRAINER
} from "../../constants";
import { renderAsDiv } from "../portal/chunk-page";
import { buildQueryString } from "../../utils/utils";
import { isAuth } from "../../services/auth-services";
import { denomAbrev, optionsSpecialities } from "../../constants/data";
import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import MessageButton from "../../components/messaging/message-button";
import SectionImage from "../../components/visual/section-image";
import { ShareWSButton, ShareFBButton, ShareXButton } from "../../utils/utils";
import { AdSearchSyllabusForm } from "../../components/ads/ad-search-form";
import AdButtonSyllabus from "../../components/ads/ad-button-syllabus";
import ShareMenu from "../../components/visual/share-menu";
import FavButton from "../../components/visual/fav";
import CenterMap from "../../components/center-map";

import background from "../../assets/background-opos.jpg";

const { Meta } = Card;
const { Text } = Typography;


export const renderItem = (item) => {
  return (
    <List.Item key={item.id} className="pt-0">
      <Card
        style={{ width: "100%" }}
        title={
          <Text
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            <h5>
              <a href={`/oposiciones${item?.absolute_url}`}>{item.ad_title}</a>
            </h5>
          </Text>
        }
        actions={
          item.creator.id != getLocalUserId() && [
            <MessageButton
              senderId={getLocalUserId()}
              recipientId={item.creator.id}
            >
              <span className="ms-2">Contactar</span>
            </MessageButton>,
            isAuth() && <FavButton contentTypeId={44} objectId={item.id} />,
          ]
        }
        extra={[
          <ShareMenu title="Comparte este anuncio" key={"share-" + item.id}>
            <ul className="share-menu">
              <li key={item.id + "-1"} className="py-2">
                <ShareWSButton
                  color="rgb(0,168,132)"
                  size="28"
                  message={
                    "¡Míralo en en Destino Docente! " +
                    `${item.ad_full_title}` +
                    ` https://destinodocente.es/oposiciones${item?.absolute_url}`
                  }
                >
                </ShareWSButton>
              </li>
              <li key={item.id + "-2"} className="py-2">
                <ShareFBButton
                  color="#1877f2"
                  size="26"
                  url={`https://destinodocente.es/oposiciones${item?.absolute_url}`}
                  message={`¡Míralo en Destino Docente! ${item.ad_full_title} `}
                >
                </ShareFBButton>
              </li>
              <li key={item.id + "-3"} className="py-2">
                <ShareXButton
                  color="#000"
                  size="26"
                  url={`https://destinodocente.es/oposiciones${item?.absolute_url}`}
                  message={`¡Míralo en Destino Docente! ${item.ad_full_title}`}
                  hashtags={["destinodocente"]}
                >
                </ShareXButton>
              </li>
            </ul>
          </ShareMenu>,
        ]}
        cover={
          <>
            {item.images && item.images.length > 0 && item.images[0].url ? (
              <Image
                src={item.images[0].url}
                alt="Foto del anuncio"
                style={{
                  maxHeight: "250px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <div
                className="text-center"
                style={{ backgroundColor: "#efefef" }}
              >
                <>
                  {!!item.municipality ?
                    <CenterMap coords={[item.municipality?.latitude, item.municipality?.longitude]} height={200} draggable={false} />
                    :
                    <div
                      className="py-4 text-center"
                      style={{ backgroundColor: "#efefef" }}
                    >
                      <FaBook size="155px"
                        style={{
                          color: "#aaaaaa",
                        }} />
                    </div>
                  }
                </>
              </div>
            )}
          </>
        }
      >
        <Row gutter={[8, 8]} className="mt-2">
          {!!item.price && item.price > 0 && (
            <Col md={24} xs={24}>
              <h5>{Math.round(item.price).toString()} €</h5>
            </Col>
          )}
          {!!item.description && (
            <Col md={24} xs={24}>
              <p>{item.description}</p>
            </Col>
          )}
          {!!item.n_rooms && (
            <Col md={4} xs={4}>
              <FaBed size={"20"} /> {item.n_rooms}
            </Col>
          )}
          {!!item.n_baths && (
            <Col md={4} xs={4}>
              <FaBath size={"18"} /> {item.n_baths}
            </Col>
          )}
          {item.municipality && (
            <Col md={24} xs={24}>
              <i className="fa fa-map-marker me-2" aria-hidden="true" />
              {item.municipality.name} ({item.municipality.province})
            </Col>
          )}
          {!!item.centers && item.centers.length > 0 && (
            <Col md={24} xs={24}>
              <i className="fa fa-map-marker " aria-hidden="true" />
              {" Cerca de " +
                denomAbrev[item.centers[0].denom] +
                " " +
                item.centers[0].name}
            </Col>
          )}
          {!!item.regions && item.regions.length > 0 && (
            <Col md={24} xs={24}>
              Comunidad Autónoma:
              <ul>
                {item.regions.map((region, index) => (
                  <li key={index}>
                    {region.name}
                  </li>
                ))}
              </ul>
            </Col>
          )}
          {!!item.specialities && (
            <Col md={24} xs={24}>
              Especialidad
              <ul>
                {item.specialities.map((speciality, index) => (
                  <li key={index}>
                    {speciality.name} ({optionsSpecialities.find(option => option.value === speciality.body)?.label || speciality.body})
                  </li>
                ))}
              </ul>
            </Col>
          )}
          {!!item.ext_url && (
            <Col md={24} xs={24} className="mb-2">
              <a href={item.ext_url} rel="noreferrer" target="_blank">{item.ext_url}</a> <FaExternalLinkAlt />
            </Col>
          )}
        </Row>
        {item.creator.id == getLocalUserId() && (
          <Row gutter={[8, 8]}>
            <Col md={24} xs={24}>
              <div className="pull-right">
                <i>
                  {item?.created ? moment(item?.created).format("D/M/YY") : ""}
                </i>
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </List.Item>
  );
};


export function AdSearchSyllabusPage(props) {
  const [ads, setAds] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchDataAd = async () => {
    const params = [
      { key: "ad_types", value: `${NEED_A_SYLLABUS},${HAVE_A_SYLLABUS},${NEED_A_TRAINER},${I_AM_A_TRAINER}` },
      { key: "api_key", value: api_key },
    ];
    const queryString = buildQueryString(params);
    const uri = `${API_ADS_URL}/search-ads/?${queryString}`;
    try {
      setLoading(true);
      const response = await axios.get(uri);
      if (response.data) {
        setAds(response.data);
      }
    } catch (error) {
      console.error("Hubo un error recuperar los datos", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchDataAd();

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("AdSearchSyllabus");
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={CLIENT_SITE_URL + "/oposiciones"} />
        <meta
          name="description"
          content="Encuentra temarios y preparadores para tu oposicion."
        />
        <title>Destino Docente. Oposiciones</title>
      </Helmet>
      {!props.isApp && <PageHeader />}
      <SectionImage title={"Oposiciones"}>
        <AdButtonSyllabus
          munCode={null}
          munName={null}
          userId={getLocalUserId()}
          afterFinish={() => {
            fetchDataAd();
          }}
        />
      </SectionImage>
      <div className="col-md-10 offset-md-1 col-xs-12 text-page">
        <div className={isMobile ? "mt-0" : "row my-4"}>
          <div className="col-md-5 col-xs-12">
            <Card
              style={{ border: "2px solid #7ebf8e" }}
              className={isMobile ? "my-4 mx-2 pt-4" : "pt-4"}
            >
              <AdSearchSyllabusForm setAds={setAds} setLoading={setLoading} />
            </Card>
            {renderAsDiv(text, "p-2")}
          </div>
          <div className="col-md-7 col-xs-12">
            <div className={isMobile ? "mx-2 mb-4" : ""}>
              {!loading ? (
                <List
                  className="item-list"
                  dataSource={ads}
                  renderItem={renderItem}
                  loading={{ indicator: <Spin size="large" />, spinning: loading }}
                  locale={{
                    emptyText: (
                      <Card
                        cover={
                          <img
                            alt="Destino Docente Anuncios"
                            src={background}
                            style={{ objectFit: "cover" }}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="my-2">
                              ¡Vaya! No hay anuncios publicados.
                            </div>
                          }
                          description={
                            <div className="text-center mt-3">
                              <AdButtonSyllabus
                                munCode={null}
                                munName={null}
                                userId={getLocalUserId()}
                                afterFinish={() => {
                                  fetchDataAd();
                                }}
                                text="Publica tu anuncio"
                              />
                            </div>
                          }
                        />
                      </Card>
                    ),
                  }}
                />
              ) : (
                <>
                  <Skeleton active />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!props.isApp && <PageFooter />}
    </>
  );
}
