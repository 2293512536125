import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { notification } from "antd";
import { clearStorageByKey } from "../../services/storage-service";
import { SITE_URL, trailing_key, G_oauth_CLIENT_ID } from "../../constants";
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  USER_EMAIL,
  USER_ID,
  USER_FIRST_NAME,
  isAuth,
  IS_SOCIAL_AUTH
} from "../../services/auth-services";
import { delay } from "../../utils/utils";

function GoogleLoginComponent(props) {
  let navigate = useNavigate();

  const handleGoogleLogin = async (response) => {
    const backendUrl = `${SITE_URL}/accounts/api/auth/google` + trailing_key;
    const token = response.credential;

    await axios
      .post(backendUrl, { token: token })
      .then((response) => {
        // Manejar la respuesta del backend
        if (response.data.success) {
          clearStorageByKey(AUTH_TOKEN);
          clearStorageByKey(REFRESH_TOKEN);
          const authToken = response.data.access_token; 
          const refreshToken = response.data.refresh_token;
          const userId = response.data.user_id;
          const userEmail = response.data.user_email;
          const userFirstName = response.data.name;
          localStorage.setItem(AUTH_TOKEN, authToken); // Guarda el token en localStorage
          localStorage.setItem(REFRESH_TOKEN, refreshToken); // Guarda el token en localStorage
          localStorage.setItem(USER_ID, userId);
          localStorage.setItem(USER_EMAIL, userEmail);
          localStorage.setItem(USER_FIRST_NAME, userFirstName);
          localStorage.setItem(IS_SOCIAL_AUTH, true);
          
          notification.success({
            message: "Hola " + userFirstName,
          });
          delay(1000);
          if (isAuth()) navigate(props.from ? props.from : "/");
        }
      })
      .catch((error) => {
        notification.error({
          message: "Se ha producido un error",
        });
      });
  };
  return (
    <GoogleOAuthProvider clientId={G_oauth_CLIENT_ID}>
      <GoogleLogin
        onSuccess={(response) => handleGoogleLogin(response)}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </GoogleOAuthProvider>
  );
}

export default GoogleLoginComponent;
