import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, notification, Tooltip } from "antd";
import {
  ExclamationCircleOutlined,
  PauseOutlined,
  CaretRightFilled
} from "@ant-design/icons";

import { API_ADS_URL, api_key, trailing_key } from "../../constants";
import axiosAuth from "../utils/auth-interceptor";
import { isAuth } from "../../services/auth-services";
import { getAdTitle } from "../../utils/utils";

const { confirm } = Modal;

const AdPublishButton = (props) => {
  let navigate = useNavigate();

  const onClick = () => {
    confirm({
      title: <div className="ps-4 ms-4">
        {!!props.ad && props.ad?.status == "P" ? "¿Quieres pausar el anuncio?" : "¿quieres publicar el anuncio?"}
      </div>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div className="text-center pt-2 pb-4">{getAdTitle(props.ad)}</div>
      ),
      onOk: () => handleToggle(),
      onCancel: () => Modal.destroyAll(),
      footer: [
        <div className="text-center">
          <Button onClick={() => Modal.destroyAll()}>No</Button>
          <Button onClick={handleToggle} className="button ms-2">
            Sí
          </Button>
        </div>,
      ],
    });
  };

  const handleToggle = async () => {
    try {
      const uri = `${API_ADS_URL}/auth/toggle_status/${trailing_key}`;
      const response = await axiosAuth.post(uri, {
        id: props.ad.id,
      });
      if (response) {
        notification.success({
          message: "El anuncio se ha actualizado correctamente",
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al actualizar el anuncio",
        });
      }
    } finally {
      props.afterFinish();
      Modal.destroyAll();
    }
  };

  return (
    <>
      {!!isAuth() && props.ad && (
        <Tooltip title={!!props.ad && props.ad?.status == "P" ? "Pausar anuncio" : "Publicar anuncio"}>
          <Button onClick={onClick}>
            {!!props.ad && props.ad?.status == "P" ? (
              <>
                <PauseOutlined />
              </>
            ) : (
              <>
                <CaretRightFilled />
              </>
            )}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default AdPublishButton;
