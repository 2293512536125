/*
Biblioteca de utilidades
*/
import { Tooltip, Button } from "antd";
import { FaWhatsapp, FaFacebook } from "react-icons/fa";
import slugify from "react-slugify";
import { optionsDenom } from "../constants/data";
import { HAVE_A_HOUSE, NEED_A_HOUSE } from "../constants";
/**
 * Capitaliza todas las palabras de un string
 * @param {*} str
 * @returns
 */
export const capitalize = (str) => {
  if (str) {
    const trimmedStr = str.trim();
    return (
      trimmedStr.charAt(0).toUpperCase() + trimmedStr.slice(1).toLowerCase()
    );
  } else {
    return "";
  }
};

/**
 * Pone en plural un texto
 * @param {*} count
 * @param {*} noun
 * @param {*} suffix
 * @returns
 */
export const pluralize = (count, noun, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

/**
 * clipboard copy
 * @param {*} e
 * @returns
 */
export function disableCopy(e) {
  e.preventDefault();
  return false;
}

/**
 * Comparte texto por Whatsapp
 * @param {*} text
 */
export function shareWhatsApp(text) {
  const msg = encodeURIComponent(text);
  const url = `https://api.whatsapp.com/send?text=${msg}`;
  window.open(url);
}

/**
 * Comparte un texto en FB
 * @param {*} title
 */
export function shareFB(url, title) {
  const link = `http://www.facebook.com/sharer.php?u=${url}&t=${title}&hashtag=${title}`;
  window.open(link);
}

export function shareX(url, title, hashtags) {
  const link = `http://x.com/share?text=${title}&url=${url}&hashtags=${hashtags}`;
  window.open(link);
}

/**
 * Devuelve un código de color para la duración de viaje
 * @param {*} duration
 * @returns
 */
export const durationColor = (duration) => {
  if (duration) {
    const nDuration = Number(duration.replace(/[^\d.-]/g, ""));
    if (nDuration < 20) {
      return "LimeGreen !important;";
    } else if (nDuration < 40) {
      return "yellow !important;";
    } else if (nDuration < 60) {
      return "darkorange !important;";
    } else {
      return "red !important;";
    }
  } else return "#B2BFA8 !important";
};

/**
 *
 * @param {*} cadena
 * @returns
 */
export function hasTildes(cadena) {
  const tildesRegex = /[áéíóúÁÉÍÓÚ]/;
  return tildesRegex.test(cadena);
}

/**
 *
 * @param {*} value
 * @param {*} options
 * @returns
 */
export function getValueLabel(options, value) {
  const option = options.find((option) => option.value === String(value));
  return option ? option.label : "";
}

/**
 *
 * @param {*} options
 * @param {*} denom
 * @returns
 */
export const getLabelValue = (options, denom) => {
  const option = options.find((option) => option.label === denom);
  return option ? option.value : null;
};

/**
 * aproxima por un factor
 * @param {*} latitud
 * @param {*} longitud
 * @param {*} divisionFactor
 * @returns
 */
export function getAproxCoords(latitud, longitud, divisionFactor = 10) {
  // Calcular el ajuste necesario para obtener coordenadas aproximadas
  const ajusteLatitud = 1 / divisionFactor;
  const ajusteLongitud = 1 / divisionFactor;

  // Calcular coordenadas aproximadas
  const latitudAproximada = Math.round(latitud / ajusteLatitud) * ajusteLatitud;
  const longitudAproximada =
    Math.round(longitud / ajusteLongitud) * ajusteLongitud;

  return [latitudAproximada, longitudAproximada];
}

/**
 * con 3 decimales es bastante preciso
 * @param {*} latitud
 * @param {*} longitud
 * @param {*} decimales
 * @returns
 */
export function getAproxCoordsRound(latitud, longitud, decimales = 1000) {
  // Redondear las coordenadas exactas a 2 decimales para obtener las coordenadas aproximadas
  const latitudAproximada = Math.round(latitud * decimales) / decimales;
  const longitudAproximada = Math.round(longitud * decimales) / decimales;

  return [latitudAproximada, longitudAproximada];
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// componente de compartir
export const ShareWSButton = ({
  color,
  size,
  message,
  className,
  children,
}) => {
  return (
    <Tooltip title="¡Comparte por WhatsApp!">
      <Button
        style={{ height: "auto", backgroundColor: { color } }}
        className={className ? className : "p-0"}
        type="button"
        onClick={() => shareWhatsApp(message)}
      >
        <FaWhatsapp color={color} size={size} />
        {children}
      </Button>
    </Tooltip>
  );
};

export const ShareFBButton = ({
  color,
  size,
  url,
  message,
  className,
  children,
}) => {
  return (
    <Tooltip title="¡Comparte en Facebook!">
      <Button
        style={{ height: "auto", backgroundColor: { color } }}
        className={className ? className : "p-0"}
        type="button"
        onClick={() => shareFB(url, message)}
      >
        <FaFacebook color={color} size={size} />
        {children}
      </Button>
    </Tooltip>
  );
};

export const ShareXButton = ({
  url,
  message,
  hashtags,
  className,
  children,
  size,
  color
}) => {
  return (
    <Tooltip title="¡Comparte en X!">
      <Button
        style={{ height: "auto", verticalAlign: "middle" }}
        className={className ? className : "p-0"}
        type="button"
        onClick={() => shareX(url, message, hashtags)}
      >
        <i
          className="fa-brands fa-x-twitter"
          style={{ fontSize: `${size}px`, color: `${color}` }}
        ></i>
        {children}
      </Button>
    </Tooltip>
  );
};

/**
 * construye la cadena de busqueda GET
 * @param {*} params
 * @returns
 */
export function buildQueryString(params) {
  return params
    .filter((param) => param.value !== null && param.value !== "")
    .map((param, index) => `${index > 0 ? "&" : ""}${param.key}=${param.value}`)
    .join("");
}

/**
 *
 * @param {*} valuesArray
 * @param {*} options
 * @returns
 */
export const convertCodesToOptions = (valuesArray, options) => {
  // Asegúrate de que userRegions sea un arreglo, incluso si está vacío
  const codesAsString = (valuesArray || []).map((code) => code.toString());
  // Filtra las opciones basadas en los códigos convertidos y extrae sus valores
  return options
    .filter((option) => codesAsString.includes(option.value))
    .map((option) => option.value);
};

export const getAdTitle = (item) => {
  return item?.ad_title;
  /*
  return (
    (item?.ad_type === "H" ? "Alquiler de" : "Busco") +
    (item?.ad_sub_type === "H"
      ? " casa"
      : item?.ad_sub_type === "F"
        ? " piso"
        : " habitación") +
    " en " +
    item?.municipality.name
  );
  */
};

export const intParser = (value) => {
  return Math.floor(parseFloat(value));
};

export const deslugify = (slug) => {
  if (!slug) return "";

  // Reemplazar guiones y guiones bajos por espacios
  const text = slug.replace(/[-_]/g, " ");

  // Capitalizar la primera letra de cada palabra
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

export function processUrlCentro(url) {
  // Dividir la URL por "/"
  const parts = url.split("/");

  // Obtener el código entre "/"
  const code = parts[2];

  // Obtener la última parte de la URL
  const lastPart = parts[parts.length - 1];

  // Dividir la última parte de la URL por "-"
  const elements = lastPart.split("-");

  // Obtener la provincia (último elemento)
  const provincia = elements.pop();

  // Reconstruir la parte relevante de la URL sin la provincia
  const slugifiedUrl = elements.join("-").toLowerCase();

  // Buscar el label adecuado en el diccionario
  let matchedOption = null;
  let matchedLabel = "";
  let matchedIndex = -1;

  for (const option of optionsDenom) {
    const slugifiedLabel = slugify(option.label);
    const index = slugifiedUrl.indexOf(slugifiedLabel);
    if (index !== -1) {
      matchedOption = option;
      matchedLabel = slugifiedLabel;
      matchedIndex = index;
      break;
    }
  }

  if (matchedOption) {
    // Obtener el nombre del centro
    const labelParts = matchedLabel.split("-");
    const elementsParts = slugifiedUrl.split(matchedLabel);
    const nombreCentro = elementsParts[0].split("-").join(" ");
    const localidad = elementsParts[1]
      .split("-")
      // .slice(labelParts.length)
      .join(" ");
    return {
      denom: matchedOption.label,
      value: matchedOption.value,
      name: nombreCentro,
      city: localidad.replace(/-/g, " "),
      province: provincia.replace(/-/g, " "),
      code: code,
    };
  } else {
    return null;
  }
}

export const firstP = (texto) => {
  const regex = /<p>(.*?)<\/p>/;
  const match = texto.match(regex);
  return match ? match[1] : "";
};

export function stripTags(text) {
  return text.replace(/<[^>]*>/g, '');
}


/**
 * Obtiene las primeras N frases de un texto.
 * @param {string} text - El texto del cual extraer las frases.
 * @param {number} N - El número de frases que deseas obtener.
 * @return {string} - Un texto que contiene las primeras N frases.
 */
export function getFirstNSentences(text, N) {
  // Expresión regular para dividir el texto en frases
  var sentences = text.match(/[^\.!\?]+[\.!\?]*/g);

  if (sentences && sentences.length > 0) {
    // Selecciona las primeras N frases y elimina espacios en blanco adicionales
    var firstNSentences = sentences.slice(0, N).map(function (sentence) {
      return sentence.trim();
    });
    // Une las frases seleccionadas en un solo texto
    return firstNSentences.join(' ');
  } else {
    // Si no se encuentran frases, devuelve una cadena vacía
    return '';
  }
}

export function getBaseUrlAd(ad) {
  return ad?.ad_type in [HAVE_A_HOUSE, NEED_A_HOUSE] ? "/compartir-casa" : "/oposiciones";
}