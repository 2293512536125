import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { API_ACCOUNTS_URL, trailing_key, CLIENT_SITE_URL, API_AUTH_URL } from "../constants";
import axiosAuth from "../components/utils/auth-interceptor";

export const AUTH_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const USER_ID = "user_id";
export const USER_EMAIL = "user_email";
export const USER_FIRST_NAME = "name";
export const IS_SOCIAL_AUTH = "social_auth";

export const REDIRECT_URL = CLIENT_SITE_URL + "/";

/**
 * bearer para peticiones a API autenticadas
 */
/*
export const authHeaders = {
  headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
};
*/
/**
 * Enrutador para urls auth
 * @param {*} param0
 * @returns
 */

export const PrivateRoute = ({ children }) => {
  const location = useLocation();

  return isAuth() ? (
    children
  ) : (
    <Navigate to="/entrar" replace state={{ from: location }} />
  );
};

export function isAuth() {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  if (!authToken) return false;
  try {
    const decoded = jwtDecode(authToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime; // Verifica si el token no ha expirado
  } catch (error) {
    console.error("Error decoding token", error);
    return false;
  }
}

export function getLocalUserId() {
  return localStorage.getItem(USER_ID);
}

export function getLocalUserEmail() {
  return localStorage.getItem(USER_EMAIL);
}

export function getLocalUserFirstName() {
  return localStorage.getItem(USER_FIRST_NAME);
}

export function getLocalAuthToken() {
  return localStorage.getItem(AUTH_TOKEN);
}

/**
 * refresca el token si ha caducado el auth_token
 */
export async function refreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  try {
    const response = await axios.post(`${API_AUTH_URL}/token/refresh${trailing_key}`, {
      refresh: refreshToken,
    });
    const newAccessToken = response.data.access;
    localStorage.setItem(AUTH_TOKEN, newAccessToken);
    return newAccessToken;
  } catch (error) {
    console.error("Error refreshing token", error);
    throw error;
  }
}

/**
 *
 * @param {*} id
 * @returns
 */
export const getUserById = async (id) => {
  const uri = API_ACCOUNTS_URL + `/user/${id}` + trailing_key;
  try {
    const response = await axiosAuth.get(uri);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// TODO: usar en componente
export const deleteUserById = async (userId) => {
  const uri = API_ACCOUNTS_URL + `/user/delete/${userId}` + trailing_key;
  try {
    const response = await axiosAuth.delete(uri);
    return response.data;
  } catch (error) {
    throw error;
  }
};
