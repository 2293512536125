import { Helmet } from "react-helmet-async";
import PageFooter from "../layout/footer";
import PageHeader from "../layout/header";

import logo from "../assets/logo.png";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>404 - Página no encontrada</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageHeader />

      <div className="col-xs-12 jumbotron-wrapper">
        <div className="container">
          <br />
          <div className="jumbotron" style={{ height: "100vh" }}>
            <div className="jumbotron-inner rounded">
              <a href="/">
                <img
                  src={logo}
                  className="img img-responsive main-logo"
                  alt="Destino Docente logo"
                />
              </a>
              <div className="lead p-4 mx-4">
                <h4 className="p-3">
                  Parece que no se ha encontrado la página indicada. <br></br>
                  Pulsa{" "}
                  <a href="/centros" className="contact">
                    <b>aquí</b>
                  </a>{" "}
                  para encontrar destinos
                </h4>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <PageFooter />
    </>
  );
}
