import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, notification, Tooltip } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import { API_ADS_URL, trailing_key } from "../../constants";
import axiosAuth from "../utils/auth-interceptor";
import { isAuth } from "../../services/auth-services";
import { getAdTitle } from "../../utils/utils";

const { confirm } = Modal;

const AdDeleteButton = (props) => {
  let navigate = useNavigate();

  const onClickDelete = () => {
    confirm({
      title: <div className="ps-4 ms-4">¿Quieres eliminar este anuncio?</div>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div className="text-center pt-2 pb-4">{getAdTitle(props.ad)}</div>
      ),
      onOk: () => handleDelete(),
      onCancel: () => Modal.destroyAll(),
      footer: [
        <div className="text-center">
          <Button onClick={() => Modal.destroyAll()}>No</Button>
          <Button onClick={handleDelete} className="danger ms-2" danger>
            SÍ
          </Button>
        </div>,
      ],
    });
  };

  const handleDelete = async () => {
    try {
      const uri = `${API_ADS_URL}/auth/${props.ad.id}/${trailing_key}`;
      const response = await axiosAuth.delete(uri);
      if (response) {
        notification.success({
          message: "El anuncio se ha eliminado correctamente",
          description: "",
        });
        props.afterFinish();
        Modal.destroyAll();
      }
    } catch (error) {
      if (error.response.status === 401) {
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al elimnar el anuncio",
        });
      }
    }
  };

  return (
    <>
      {!!isAuth() && props.ad && (
        <Tooltip title="Eliminar anuncio">
          <Button
            onClick={onClickDelete}
            danger
            className="pt-0"
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default AdDeleteButton;
