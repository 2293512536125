import React, { useState } from "react";
import { Popup, Popover } from "antd-mobile";
import { Button } from "antd";
import { isMobile } from "react-device-detect";
import { ShareAltOutlined } from "@ant-design/icons";

const ShareMenu = ({ children, title }) => {
  const [visible, setVisible] = useState(false);

  const toggleMenu = () => {
    setVisible(!visible);
  };

  return (
    <>
      {isMobile ? (
        <>
          <Button
            fill="none"
            shape="circle"
            onClick={toggleMenu}
            style={{ border: "none", padding: 0 }}
          >
            <ShareAltOutlined />
          </Button>
          <Popup
            visible={visible}
            onMaskClick={toggleMenu}
            position="bottom"
            bodyStyle={{ padding: "16px 8px" }}
          >
            <h5>{!!title ? title : "Comparte"}</h5>
            {children}
          </Popup>
        </>
      ) : (
        <Popover
          content={children}
          visible={visible}
          onVisibleChange={setVisible}
          trigger="click"
          placement="bottom-end"
        >
          <Button
            fill="none"
            style={{ border: "none" }}
          >
            <ShareAltOutlined />
          </Button>
        </Popover>
      )}
    </>
  );
};

export default ShareMenu;
