import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import RoutesCenters from "./routes";
import Bugsnag from "@bugsnag/js";
import CookieConsent from "react-cookie-consent";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import { HelmetProvider } from "react-helmet-async";
import { AUTH_TOKEN } from "./services/auth-services";
import axiosAuth from "./components/utils/auth-interceptor";
import App from "./App";
import "./index.css";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const container = document.getElementById("root");
const authToken = localStorage.getItem(AUTH_TOKEN);
if (authToken) {
  axiosAuth.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
}

const app = (
  <React.StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <RoutesCenters />
        <ConfigProvider locale={esES}>
          <App />
        </ConfigProvider>
      </HelmetProvider>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Aceptar"
        declineButtonText="Rechazar"
        cookieName="cookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ backgroundColor: "#EBF2EE", fontSize: "14px" }}
        expires={150}
      >
        Utilizamos cookies para mejorar tu experiencia en nuestro sitio web.
        Pulsa "Aceptar" para continuar navegando.
      </CookieConsent>
    </ErrorBoundary>
  </React.StrictMode>
);

if (container.hasChildNodes()) {
  hydrateRoot(container, app);
} else {
  createRoot(container).render(app);
}

reportWebVitals();
