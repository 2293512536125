import React, { useState, useEffect } from "react";
import { Skeleton } from "antd-mobile";
import { Helmet } from "react-helmet-async";
import { getChunkByKey } from "../../services/utils-services";
import { CLIENT_SITE_URL } from "../../constants";

import { renderAsDiv } from "./chunk-page";

function RawPage(props) {
  const [chunk] = useState(props.chunkKey);
  const [text, setText] = useState("");

  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  useEffect(() => {
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk(chunk);
  }, [chunk]);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={CLIENT_SITE_URL + (props.path ? props.path : "")}
        />
        <meta name="description" content={props.title} />
      </Helmet>
      {text ? renderAsDiv(text) : <Skeleton active />}
    </>
  );
}

export default RawPage;
