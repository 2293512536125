import React, { useEffect, useState } from "react";
import axios from "axios";

import { CLIENT_SITE_URL } from "../constants";
import background from "../assets/logo.png";
import logoLC from "../assets/library_bg.jpg";

const EmbeddedContent = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const uri = CLIENT_SITE_URL + "/proxy.php" + (props.uri ? "?url=" + props.uri : "");
        const response = await axios.get(uri, {
          headers: {
            "Content-Type": "text/html",
          },
        });
        setContent(response.data);
      } catch (error) {
        console.error("Error fetching content:");
      }
    };

    fetchContent();
  }, []);

  return (
    <>
      {!!content ? (
        <div dangerouslySetInnerHTML={{ __html: content }} className={props.className} />
      ) : (
        <>
          {!!props.background && (
            <img
              alt="Destino Docente publicidad"
              src={background}
              style={{ maxWidth: "99vw", objectFit: "cover" }}
              className="img img-responsive main-logo"
            />
          )}
          <div className="jumbotron my-4 mb-5 mx-1">
            <div className="card col-md-8 col-xs-11 offset-md-2">
              <a href="https://api2.destinodocente.es/portal/redirect?url=https://littlecoders.dev/teachers&utm_source=home1-1" target="_blank">
                <div className="banner-image" style={{ position: "relative", left: 0, }}>
                  <img src={logoLC} alt="Agenda escolar LittleCoders.dev" style={{ width: "100%" }} loading="lazy" decoding="async" />
                </div>
                <div className="banner-title-overlay" style={{ display: "flex", alignItems: "center", height: "100%" }}>
                  <h5 style={{ textAlign: "center", width: "100%" }}>
                    Ayuda a tus alumnos a <b>aprender programación</b> con la <b>agenda interactiva</b> littlecoders.dev.
                  </h5>
                </div>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EmbeddedContent;
