import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  List,
  Row,
  Col,
  Card,
  notification,
  Typography,
  Skeleton,
  Image,
} from "antd";
import { Tabs } from "antd-mobile";
import {
  FaHouseUser,
  FaUsers,
  FaBed,
  FaBath,
  FaBuilding,
  FaExternalLinkAlt
} from "react-icons/fa";
import moment from "moment/moment";
import { getChunkByKey } from "../../services/utils-services";
import { getLocalUserId } from "../../services/auth-services";
import {
  API_ADS_URL,
  API_FAVS_URL,
  CT_ADS,
  trailing_key_param,
  trailing_key,
  HAVE_A_HOUSE,
  NEED_A_HOUSE
} from "../../constants";
import { renderAsDiv } from "../portal/chunk-page";
import { getBaseUrlAd } from "../../utils/utils";
import { denomAbrev } from "../../constants/data";
import axiosAuth from "../../components/utils/auth-interceptor";
import PageHeader from "../../layout/header";
import SectionImage from "../../components/visual/section-image";
import { ShareWSButton, ShareFBButton, ShareXButton } from "../../utils/utils";
import AdButton from "../../components/ads/ad-button";
import AdButtonSyllabus from "../../components/ads/ad-button-syllabus";
import AdDeleteButton from "../../components/ads/ad-delete-button";
import ShareMenu from "../../components/visual/share-menu";
import MessageButton from "../../components/messaging/message-button";
import FavButton from "../../components/visual/fav";
import AdPublishButton from "../../components/ads/ad-publish-button";
import { AdButtonsDropdown } from "../../components/ads/ad-buttons-wrapper";

import background from "../../assets/background-ad.jpg";


const { Meta } = Card;
const { Text } = Typography;


function UserAdsPage() {
  const [ads, setAds] = useState([]);
  const [favAds, setFavAds] = useState([]);
  const [text, setText] = useState("");
  let navigate = useNavigate();

  function fetchUserFavAds(contentTypeId) {
    const uri = `${API_FAVS_URL}/auth/fav_by_contentype_user/?content_type=${contentTypeId}${trailing_key_param}`;
    axiosAuth
      .get(uri)
      .then((response) => {
        if (response.data) {
          setFavAds(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const stateObj = { state: { from: window.location.pathname } };
          navigate("/salir", stateObj);
        } else {
          notification.warning({
            message: "Se ha producido un error",
          });
        }
      });
  }

  function fetchUserAds() {
    const uri = `${API_ADS_URL}/auth/user-ads/${trailing_key}`;
    axiosAuth
      .get(uri)
      .then((response) => {
        if (response.data) {
          setAds(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const stateObj = { state: { from: window.location.pathname } };
          navigate("/salir", stateObj);
        } else {
          notification.warning({
            message: "Se ha producido un error",
          });
        }
      });
  }

  const renderItem = (item) => {
    return (
      <List.Item key={item.id} className="pt-0">
        <Card
          style={{
            minHeight: "400px",
            backgroundColor: item.status == "D" ? "#efefef" : "",
          }}
          title={
            <Text
              style={{
                whiteSpace: "normal" /* Permite saltos de línea */,
                wordBreak: "break-word" /* Rompe las palabras largas */,
              }}
            >
              <h4>
                {item.ad_type === HAVE_A_HOUSE ? (
                  <FaHouseUser
                    size="18"
                    style={{ marginTop: "-2px", color: "LimeGreen" }}
                  />
                ) : (
                  <FaUsers
                    size="16"
                    style={{ marginTop: "-2px", color: "darkred" }}
                  />
                )}
                <span className="ms-2">{item.ad_title}</span>
              </h4>
            </Text>
          }
          cover={
            item.images && item.images.length > 0 && item.images[0].url ? (
              <Image
                src={item.images[0].url}
                alt="Foto del anuncio"
                style={{
                  maxHeight: "250px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <div
                className="py-4 text-center"
                style={{ backgroundColor: "#efefef" }}
              >
                <FaBuilding
                  size="155px"
                  style={{
                    color: "#aaaaaa",
                  }}
                />
              </div>
            )
          }
          actions={[
            item.creator.id == getLocalUserId() && (
              (item && item.ad_type == HAVE_A_HOUSE || item.ad_type == NEED_A_HOUSE ? (
                <AdButton
                  ad={item}
                  afterFinish={() => {
                    fetchUserAds();
                  }}
                  title="Editar anuncio"
                  className=" "
                />
              ) : (
                <AdButtonSyllabus
                  ad={item}
                  afterFinish={() => {
                    fetchUserAds();
                  }}
                  title="Editar anuncio"
                  className=" "
                />
              ))
            ),

            item.creator.id == getLocalUserId() && (
              <AdPublishButton
                userId={getLocalUserId()}
                ad={item}
                afterFinish={() => {
                  afterAdDelete();
                }}
              />
            ),
            item.creator.id == getLocalUserId() && (
              <AdDeleteButton
                userId={getLocalUserId()}
                ad={item}
                afterFinish={() => {
                  afterAdDelete();
                }}
              />
            ),
            item.creator.id != getLocalUserId() && (
              <MessageButton
                senderId={getLocalUserId()}
                recipientId={item.creator.id}
              >
                <span className="ms-2">Contactar</span>
              </MessageButton>
            ),
            item.creator.id != getLocalUserId() && (
              <FavButton contentTypeId={44} objectId={item.id} />
            ),
          ]}
          extra={[
            <ShareMenu title="Comparte este anuncio" key={"share-" + item.id}>
              <ul className="share-menu">
                <li key={item.id + "-1"} className="py-2">
                  <ShareWSButton
                    color="rgb(0,168,132)"
                    size="28"
                    message={
                      "¡Míralo en en Destino Docente! " +
                      `${item.ad_title}` +
                      ` https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`
                    }
                  >
                  </ShareWSButton>
                </li>
                <li key={item.id + "-2"} className="py-2">
                  <ShareFBButton
                    color="#1877f2"
                    size="26"
                    url={`https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`}
                    message={`¡Míralo en Destino Docente! ${item.ad_title} `}
                  >
                  </ShareFBButton>
                </li>
                <li key={item.id + "-3"} className="py-2">
                  <ShareXButton
                    color="#000000"
                    size="26"
                    url={`https://destinodocente.es${getBaseUrlAd(item)}${item?.ad_title}}`}
                    message={`¡Míralo en Destino Docente! ${item.ad_title} `}
                    hashtags="destinodocente,oposiciones"
                  >
                  </ShareXButton>
                </li>
              </ul>
            </ShareMenu>,
          ]}
        >
          <Row gutter={[8, 8]} className="mt-2">
            {!!item.price && item.price > 0 && (
              <Col md={24} xs={24}>
                <h5>{Math.round(item.price).toString()} €</h5>
              </Col>
            )}
            {!!item.description && (
              <Col md={24} xs={24}>
                <p style={{ height: "50px", overflow: "auto" }}>{item.description}</p>
              </Col>
            )}
            {!!item.n_rooms && (
              <Col md={4} xs={4}>
                <FaBed size={"20"} /> {item.n_rooms}
              </Col>
            )}
            {!!item.n_baths && (
              <Col md={4} xs={4}>
                <FaBath size={"18"} /> {item.n_baths}
              </Col>
            )}
            {item.municipality && (
              <Col md={24} xs={24}>
                <i className="fa fa-map-marker me-2" aria-hidden="true" />
                {item.municipality.name} ({item.municipality.province})
              </Col>
            )}
            {!!item.centers && item.centers.length > 0 && (
              <Col md={16} xs={24}>
                <i className="fa fa-map-marker" />
                {" Cerca de " +
                  denomAbrev[item.centers[0].denom] +
                  " " +
                  item.centers[0].name}
              </Col>
            )}
            {!!item.regions && item.regions.length > 0 && (
              <Col md={24} xs={24}>
                Comunidad Autónoma:
                <ul>
                  {item.regions.map((region, index) => (
                    <li key={index}>
                      {region?.name}
                    </li>
                  ))}
                </ul>
              </Col>
            )}
            {!!item.specialities && item.specialities.length > 0 && (
              <Col md={24} xs={24}>
                Especialidad
                <ul>
                  {item.specialities.map((speciality, index) => (
                    <li key={index}>
                      {speciality.name}
                    </li>
                  ))}
                </ul>
              </Col>
            )}
            {!!item.ext_url && (
              <Col md={24} xs={24} className="mb-2">
                <a href={item.ext_url} rel="noreferrer" target="_blank">{item.ext_url}</a> <FaExternalLinkAlt />
              </Col>
            )}
          </Row>
          <Row gutter={[8, 8]}>
            {/*
            <Col md={24} xs={24} className="d-none">
              <div className="mt-2">
                {item.creator.id != getLocalUserId() ? (
                  <></>
                ) : (
                  <>
                    <FaUserAstronaut /> {item.creator.first_name}
                  </>
                )}
              </div>
            </Col>
            */}
            {item.creator.id == getLocalUserId() && (
              <Col md={24} xs={24}>
                <div className="pull-right">
                  <i>
                    {item?.created
                      ? moment(item?.created).format("D/M/YY")
                      : ""}
                  </i>
                </div>
              </Col>
            )}
          </Row>
        </Card>
      </List.Item>
    );
  };

  useEffect(() => {
    document.title = "Destino Docente. Tus anuncios";
  }, []);

  useEffect(() => {
    fetchUserAds();
    fetchUserFavAds(CT_ADS);

    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        ;// console.log("Error fetching chunk");
      }
    };

    fetchDataChunk("AdUser");
  }, []);

  const afterAdDelete = () => {
    fetchUserAds();
  };

  return (
    <>
      <PageHeader />
      <SectionImage title={"Tus anuncios"}>
        <AdButtonsDropdown userId={getLocalUserId()}
          afterFinish={() => {
            fetchUserAds();
          }} />
      </SectionImage>
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        {renderAsDiv(text)}
        <Tabs>
          <Tabs.Tab title={"Publicados por tí"} key="pubs" className="p-2">
            {!!ads ? (
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 3,
                }}
                dataSource={ads}
                renderItem={renderItem}
                locale={{
                  emptyText: (
                    <>
                      <Card
                        cover={
                          <img
                            alt="Destino Docente Anuncios"
                            src={background}
                            style={{ objectFit: "cover"}}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="mt-4">
                              ¡Vaya! No tienes anuncios publicados.
                            </div>
                          }
                          description={
                            <>
                              <AdButtonsDropdown
                                munCode={null}
                                munName={null}
                                userId={getLocalUserId()}
                                afterFinish={() => {
                                  fetchUserAds();
                                }}
                                className="p-0"
                                text=" Publica tu primer anuncio"
                                ad={null}
                              />
                              <br/> <br/>
                              <Link to="/compartir-casa">
                                {" "}
                                <span className="underlined">
                                  Busca anuncios para compartir casa
                                </span>
                              </Link>
                              <Link to="/oposiciones">
                                {" "}
                                <span className="underlined">
                                  o busca temarios y preparadores.
                                </span>
                              </Link>
                            </>
                          }
                        />
                      </Card>
                    </>
                  ),
                }}
              />
            ) : (
              <>
                <Skeleton active />
              </>
            )}
          </Tabs.Tab>
          <Tabs.Tab title={"Favoritos"} key="favs" className="p-2">
            {!!favAds ? (
              <List
                className="item-list"
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 3,
                }}
                dataSource={favAds}
                renderItem={renderItem}
                locale={{
                  emptyText: (
                    <>
                      <Card
                        cover={
                          <img
                            alt="Destino Docente anuncios favoritos"
                            src={background}
                            style={{ objectFit: "cover"}}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="mt-4">
                              No tienes anuncios favoritos.
                            </div>
                          }
                          description={
                            <>
                              <AdButtonsDropdown
                                munCode={null}
                                munName={null}
                                userId={getLocalUserId()}
                                afterFinish={() => {
                                  fetchUserAds();
                                }}
                                className="p-0"
                                text=" Publica tu primer anuncio"
                                ad={null}
                              />
                              <br/> <br/>
                              <Link to="/compartir-casa">
                                {" "}
                                <span className="underlined">
                                  Busca anuncios para compartir casa
                                </span>
                              </Link>
                              <Link to="/oposiciones">
                                {" "}
                                <span className="underlined">
                                  o busca temarios y preparadores.
                                </span>
                              </Link>
                            </>
                          }
                        />
                      </Card>
                    </>
                  ),
                }}
              />
            ) : (
              <>
                <Skeleton active />
              </>
            )}
          </Tabs.Tab>
        </Tabs>
      </div>
    </>
  );
}

export default UserAdsPage;
