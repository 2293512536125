import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/es";
import { Table, Spin } from "antd";
import Plot from "react-plotly.js";
import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import { Selector } from "antd-mobile";
import { FaTaxi, FaMap } from "react-icons/fa";

import PageHeader from "../../layout/header";
import PageFooter from "../../layout/footer";
import Map from "../../components/centers-map";
import SectionImage from "../../components/visual/section-image";
import { getLastPlazas } from "../../services/center-services";
import {
  capitalize,
  ShareWSButton,
  ShareFBButton,
  ShareXButton,
} from "../../utils/utils";
import { CLIENT_SITE_URL } from "../../constants";
import { antIcon } from "../../components/visual/load-icon";

import logoData from "../../assets/logo_data.png";

function PositionsList() {
  const PES = "00590"; // codigos de andalucia
  const MAESTRO = "00597";
  //  parámetros en url
  const {
    region: pRegion,
    regionName: pRegionName,
    denom: pDenom,
    denomnName: pDenomnName,
  } = useParams();

  const [region, setRegion] = useState(1); // AND
  const [regionName, setRegionName] = useState("Andalucía"); // AND
  const [denom, setDenom] = useState(PES); // PES
  const [denomName, setDenomName] = useState("I.E.S"); // IES
  const [positions, setPositions] = useState([]);
  const [dateLast, setDateLast] = useState("");
  const [markers, setMarkers] = useState([]);
  const [defaultMapCenterCoords] = useState([40.189214, -3.667379]); //Centro
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const buildCanonicalUrl = () => {
    let url = CLIENT_SITE_URL + "/vacantes";
    if (pRegion && pRegionName) {
      url += `/${pRegion}/${encodeURIComponent(pRegionName)}`;
    }
    return url;
  };

  useEffect(() => {
    // si hay parámetros GET
    if (pRegion) {
      setRegion(pRegion);
    }
  }, [pRegion]);

  useEffect(() => {
    // si hay parámetros GET
    if (pDenom) {
      setDenom(pDenom);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const pos = JSON.parse(await getLastPlazas());
        setPositions(pos);

        let fecha = moment(pos[0].date);
        fecha.locale("es"); // Establecer el idioma español
        setDateLast(fecha.format("LL"));

        // conversion para mapa
        const marks = pos.map((item) => {
          return {
            id: item.idCenter,
            latitude: item.centro_latitude,
            longitude: item.centro_longitude,
            name: item.centro_name,
            denom: item.centro_denom,
            city: item.centro_city,
            province: item.centro_province,
            code: item.centro_code,
          };
        });
        setMarkers(marks);
      } catch (error) {
        console.log("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function Histogram({ positions }) {
    const layoutTree = {
      autosize: true,
      margin: { l: 10, r: 10, t: 0, b: 0 },
      treemapcolorway: ["#7ebf8e", "#26400C", "#5F734C"],
    };
    const config = {
      displayModeBar: false,
    };

    const histogramData = {};
    const searchString = denom;

    // filtrado de datos
    for (const key in positions) {
      const position = positions[key].position;
      const npositions = positions[key].npositions;

      if (position.includes(searchString)) {
        if (histogramData[position]) {
          histogramData[position] += npositions;
        } else {
          histogramData[position] = npositions;
        }
      }
    }
    // Convierte el objeto agrupado en una lista ordenada por nombre
    const histogramList = Object.entries(histogramData).sort((a, b) => {
      const codeA = a[0].split(" - ")[1];
      const codeB = b[0].split(" - ")[1];
      return codeA.localeCompare(codeB);
    });

    //plotly
    //barchart
    const chartData = {
      x: histogramList.map(([position]) => position.split(" - ")[1]),
      y: histogramList.map(([, count]) => count),
      type: "bar",
      marker: { color: "rgba(75, 192, 192, 0.2)" },
      line: { color: "rgba(75, 192, 192, 1)" },
    };
    const layoutBar = {
      title: "Vacantes de " + pRegionName,
      xaxis: {
        title: "Puesto",
        tickfont: { size: 11 },
      },
      yaxis: { title: "Num. Vacantes" },
    };

    //treemap data
    const labelsTree = histogramList.map((item) => item[0].split(" - ")[1]);
    const valuesTree = histogramList.map((item) => item[1]);
    const chartDataTree = {
      labels: labelsTree,
      values: valuesTree,
      parents: labelsTree.map(() => ""),
      type: "treemap",
      textinfo: "label+value",
    };

    // Prepara los datos para la tabla
    const tableData = histogramList.map(([position, count]) => ({
      position,
      count,
    }));

    const columns = [
      {
        title: "Nombre del puesto",
        dataIndex: "position",
        key: "position",
        filterMode: "menu",
        filterSearch: true,
        sorter: (a, b) =>
          a.position.split(" - ")[1] > b.position.split(" - ")[1],
        onFilter: (value, record) => record.position.indexOf(value) === 0,
        render: (text) => (
          <>
            {
              <>
                <a
                  href={
                    "/mapas/puesto/" +
                    text.split(" - ")[0] +
                    "/" +
                    encodeURI(capitalize(text.split(" - ")[1]))
                  }
                >
                  {text.split(" - ")[0]} {capitalize(text.split(" - ")[1])}{" "}
                  <FaMap className="underlined" />
                </a>
              </>
            }
          </>
        ),
      },
      {
        title: "N. plazas",
        dataIndex: "count",
        key: "count",
        sorter: (a, b) => a.count - b.count,
      },
    ];

    return (
      <div>
        {!!tableData ? (
          <>
            {/*
            <Plot
              data={[chartData]}
              layout={layoutBar}
              useResizeHandler={true}
              style={{ width: "90%" }}
              displayModeBar={false}
            />
           */}

            <center>
              <h4 className="mb-4 mt-2" style={{ width: "90%" }}>
                Vacantes por especialidad
              </h4>
              <Plot
                data={[chartDataTree]}
                layout={layoutTree}
                config={config}
                style={{ width: "90%", height: "90%" }}
                loading={loading}
              />

              <Table
                dataSource={tableData}
                columns={columns}
                pagination={false}
                className="mt-4"
                style={{ width: "90%" }}
                //loading={loading}
                loading={{
                  indicator: <Spin size="large" indicator={antIcon} />,
                  spinning: loading,
                }}
              />
            </center>
          </>
        ) : (
          <>Aún no se han publicado vacantes</>
        )}
      </div>
    );
  }

  const back = () => {
    const isDirectAccess =
      window.performance.getEntriesByType("navigation")[0].type === "navigate";

    if (isDirectAccess) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  const title =
    positions.length > 0
      ? `Vacantes ${dateLast} ${capitalize(pRegionName)} ${capitalize(
          pDenomnName
        )}`
      : `Vacantes publicadas`;

  return (
    <>
      <Helmet>
        <title>
          Destino Docente. Análisis de últimas vacantes publicadas SIPRI
        </title>
        <link rel="canonical" href={buildCanonicalUrl()} />
        <meta
          name="description"
          content="Vacantes publicadas. Ahorra tiempo encontrando centros para tu solicitud de destino de secundaria y primaria"
        />
        <meta
          property="og:title"
          content={"Destino Docente. Análisis de últimas vacantes publicadas"}
        />
        <meta
          property="og:description"
          content="Ahorra tiempo encontrando centros para tu solicitud de destino"
        />
        <meta
          property="og:image"
          content="https://destinodocente.es/logo_data.png"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://destinodocente.es/vacantes/1/andalucia`}
        />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={"Destino Docente. Análisis de últimas vacantes publicadas"}
        />
        <meta
          name="twitter:image"
          content="https://destinodocente.es/logo_data.png"
        />
        <meta name="twitter:domain" content="https://destinodocente.es" />
      </Helmet>
      <PageHeader />
      <SectionImage title={title}>
        <ShareWSButton
          color="rgb(255,255,255)"
          size="24"
          message={`¡Míralo en Destino Docente! Vacantes SIPRI publicadas ${dateLast} ${regionName} ${denomName} 
          https://destinodocente.es/vacantes/${region}/${regionName}`}
        />
        <ShareFBButton
          color="rgb(255,255,255)"
          size="24"
          className="px-2"
          url={encodeURI(
            `https://destinodocente.es/vacantes/${region}/${regionName}`
          )}
          message={`¡Míralo en Destino Docente! Vacantes SIPRI publicadas ${dateLast} ${regionName} ${denomName}`}
        />
        <ShareXButton
          color="#ffffff"
          size="24"
          url={`https://destinodocente.es/vacantes/${region}/${regionName}`}
          message={`¡Míralo en Destino Docente! Vacantes SIPRI publicadas ${dateLast} ${regionName} ${denomName}`}
          hashtags={"destinodocente,SIPRI,Andalucia"}
        />
      </SectionImage>
      <div className={isMobile ? "" : "row"}>
        <div
          className="col-md-8 offset-md-2 col-xs-12 text-page"
          style={{ minHeight: "75vh" }}
        >
          {!!positions > 0 && (
            <center>
              <h4 className=" py-4">Distribución geográfica</h4>
              <div
                className="rounded"
                style={{
                  position: "relative",
                  height: "33vh",
                  width: "90%",
                }}
              >
                <Map
                  centerCoords={defaultMapCenterCoords} // centro de España
                  markers={markers}
                  radius={null}
                  coords={defaultMapCenterCoords}
                  clustered={true}
                />
              </div>
            </center>
          )}
          <center className="mt-4">
            <Selector
              defaultValue={[PES]}
              onChange={(value) => setDenom(value)}
              options={[
                {
                  label: "Profesores",
                  value: PES,
                },
                {
                  label: "Maestros",
                  value: MAESTRO,
                },
              ]}
              showCheckMark={false}
              style={{
                "--border": "solid transparent 1px",
              //  "--checked-border": "solid var(--adm-color-primary) 1px",
                "--padding": "8px 24px",
                "--color": "#EBF2EE",
                "--checked-color": "#7ebf8e",
                "--checked-text-color": "white",
                "--checked-border": "solid #7ebf8e 1px",
              }}
            />
          </center>
          <br />

          <Histogram positions={positions} />

          <div className="text-center">
            <img
              src={logoData}
              className="img img-responsive my-4 text-center"
              alt="Análisis de datos. Destinos docentes de Andalucía, Aragón, Catilla-La Mancha, Castilla y León
              Comunidad de Madrid, Comunidad Valenciana y Región de Murcia"
              style={{ maxWidth: "20%" }}
            />
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default PositionsList;
