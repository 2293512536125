import React, { useState, useEffect } from "react";
import axios from "axios";
import "moment/locale/es"; // Importar el locale español
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Select, Spin } from "antd";
import { API_ADS_URL, trailing_key_param } from "../../constants";
import { capitalize } from "../../utils/utils";
import { optionsSpecialities } from "../../constants/data"; 

export function SpecialityAutocomplete(props) {
  const [options, setOptions] = useState([]);
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = async (value) => {
    if (value.length > 1) {
      try {
        let uri = API_ADS_URL + `/speciality/by-name/?name=${value}`;
        if (body) 
          uri += `&body=${body}`;
        
        uri += `&body=${trailing_key_param}`;
        const response = await axios.get(uri);
        if (response.data.length > 0) {
            const options = response.data.map((record) => {
            const bodyLabel = optionsSpecialities.find(option => option.value === record.body)?.label || record.body;
            return {
              value: record.name + " (" + bodyLabel + ")",
              label: (
              <div
                style={{
                display: "flex",
                justifyContent: "space-between",
                }}
              >
                <span>{capitalize(record.name)} ({bodyLabel}) </span>
                <span className="coords d-none">{record.name}</span>
                <span className="code d-none">{record.code}</span>
              </div>
              ),
            };
            });
          setOptions(options);
        }
      } catch (error) {
        setOptions([]);
        console.error("Error fetching specialities:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
  {/* 
      <Select
        style={{ width: 200, marginBottom: 10 }}
        placeholder="Selecciona un cuerpo"
        onChange={(value) => setBody(value)}
        allowClear
      >
        {optionsSpecialities.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
  */}

      <AutoComplete
        options={options}
        autoFocus={props.autofocus}
        onSelect={props.onSelectSpecialities}
        onSearch={handleSearch}
        defaultValue={props.defaultValue}
        allowClear={true}
        onClear={props.onClearSpecialities}
        disabled={props.disabled}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder={"Escribe una especialidad"}
          style={{ width: "100%" }}
        />
      </AutoComplete>{" "}
    </>
  );
}


/**
 * No funciona bien
 * @param {*} props 
 * @returns 
 */
export function SpecialitySelect(props) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allOptions, setAllOptions] = useState([]);

  const handleSearch = (value) => {
    const filteredOptions = allOptions.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  useEffect(() => {
    const fetchAllSpecialities = async () => {
      setLoading(true);
      console.log("Fetching specialities...");
      try {
        const uri = API_ADS_URL + `/speciality/all/?${trailing_key_param}`;
        console.log(uri);
        const response = await axios.get(uri);
        if (response.data.length > 0) {
          const options = response.data.map((record) => ({
            value: record.name + " (" + record.body + ")",
            label: `${capitalize(record.name)} (${record.body})`,
          }));
          setAllOptions(options);
          setOptions(options);
        }
      } catch (error) {
        setOptions([]);
        console.error("Error fetching specialities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllSpecialities();
  }, []);

  return (
    <Select
      showSearch
      onSearch={handleSearch}
      onSelect={props.onSelectSpecialities}
      defaultValue={props.defaultValue}
      allowClear={true}
      onClear={props.onClearSpecialities}
      disabled={props.disabled}
      notFoundContent={loading ? <Spin size="small" /> : null}
      style={{ width: "100%" }}
      optionFilterProp="children"
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}
