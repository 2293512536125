import React from "react";
import { Layout, Badge, Avatar } from "antd";
import { FaSignInAlt } from "react-icons/fa";
import MainMenu from "../components/visual/main-menu";
import UserMenu from "../components/visual/user-menu";
import { isAuth } from "../services/auth-services";
import logo from "../assets/logo.png";

const { Header } = Layout;

const PageHeader = () => {
  return (
    <Header className="header">
      <MainMenu className="pull-left mx-2" marginTop={64} />
      <div className="ms-2 pull-left">
        <a href="/">
          <img
            src={logo}
            className="logo"
            alt="Logo Destino Docente. Encuentra destinos docentes cercanos a ti y comparte coche"
            height={45}
            width={110}
          />
        </a>
      </div>
      {/*
      <div className="blog d-none d-md-flex justify-content-center" style={{ paddingTop: "12px" }}>
        <a className="btn mt-0 text-white" href="/blog"  >Lee nuestro Blog</a>
      </div>

      <Badge.Ribbon
          placement="end"
          text={<a className="text-white" href="/blog" >Blog</a>}
          color="rgba(246, 9, 9, 0.8)"
          className="d-md-none"
          style={{ marginLeft: "-1.5rem", marginTop: "-7px" }}
      />
      */}

      <div className="user-menu">
        {!!isAuth() ? (
          <UserMenu />
        ) : (
          <a href="/entrar" className="btn-action-user me-3">
            <span style={{ fontSize: "1rem" }} className="me-2">Iniciar sesión</span>{" "}
            <FaSignInAlt style={{ marginTop: "-5px" }} />
          </a>
        )}
      </div>
    </Header>
  );
};

export default PageHeader;
